<template>
  <div
    v-if="teamStore.fetchingMembers"
    class="flex justify-center items-center py-6"
  >
    <span
      class="animate-spin h-6 w-6 border-4 border-blue-500 border-t-transparent rounded-full"
    ></span>
  </div>
  <div v-if="members.length > 0">
    <section>
      <p class="pb-4 m-2 text-xs md:text-sm text-gray-500 dark:text-gray-300">
        View and manage members of your team.
      </p>

      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-4">
        <div
          v-for="user in members"
          :key="user.id"
          :class="[
            'bg-white dark:bg-gray-900 rounded-lg shadow-lg border overflow-hidden',
            userStore.userData.id === user.id
              ? 'border-blue-500 dark:border-blue-400 '
              : 'border-gray-200 dark:border-gray-700',
          ]"
        >
          <div class="flex p-4 items-center space-x-4">
            <div
              class="w-12 h-12 bg-gray-300 dark:bg-gray-700 rounded-full flex items-center justify-center text-white font-medium text-lg"
            >
              {{ user.name[0] }}
            </div>

            <div class="flex-1">
              <p class="text-lg font-medium text-gray-900 dark:text-gray-100">
                {{ user.name }}

                <span
                  class="text-sm font-normal text-gray-500 dark:text-gray-400"
                >
                  ({{ toTitleCase(user.role) }})
                </span>

                <span
                  v-if="userStore.userData.id === user.id"
                  class="ml-2 px-2 py-0.5 text-xs font-semibold text-blue-600 bg-blue-100 rounded-full dark:bg-blue-800 dark:text-white"
                  title="This is you"
                >
                  You
                </span>
              </p>
              <a
                :href="`mailto:${user.email}`"
                class="text-sm text-gray-600 dark:text-gray-400 hover:text-blue-600 dark:hover:text-blue-400 transition"
              >
                {{ user.email }}
              </a>
            </div>
          </div>

          <div
            class="flex justify-between p-4 border-t border-gray-200 dark:border-gray-700"
          >
            <button
              class="text-xs md:text-sm text-blue-600 dark:text-blue-400 hover:text-blue-800 dark:hover:text-blue-600 flex items-center gap-1 disabled:text-gray-400 dark:disabled:text-gray-600"
              :disabled="loading || user.role === 'owner'"
              @click="openRoleModal(user)"
            >
              <Pencil :size="14" /> Edit
            </button>

            <button
              class="text-xs md:text-sm text-red-500 dark:text-red-400 hover:text-red-700 dark:hover:text-red-600 flex items-center gap-1 disabled:text-gray-400 dark:disabled:text-gray-600"
              :disabled="
                loading || members.length == 1 || user.role === 'owner'
              "
              @click="confirmRemove(user)"
            >
              <UserRoundX :size="16" />
              {{ userStore.userData?.id === user.id ? 'Leave' : 'Remove' }}
            </button>
          </div>
        </div>
      </div>
    </section>
    <ConfirmationModal
      :show="showConfirmationModal"
      @close="showConfirmationModal = false"
    >
      <template #title>{{
        selfLeave ? 'Confirm Leave' : 'Confirm Remove'
      }}</template>
      <template #content>
        {{
          selfLeave
            ? 'Are you sure you want to leave'
            : 'Are you sure you want to remove'
        }}

        <span v-if="!selfLeave"
          ><strong>{{ userToDelete?.name }}</strong> from
          <strong>{{ currentTeam?.name }}</strong>
        </span>
        <span v-else
          ><strong>{{ currentTeam?.name }}</strong> </span
        >? This action cannot be undone.
      </template>
      <template #footer>
        <button
          class="bg-gray-200 text-gray-700 px-4 py-2 mr-2 rounded"
          @click="showConfirmationModal = false"
        >
          Cancel
        </button>
        <button
          class="bg-red-600 text-white px-4 py-2 rounded"
          :disabled="loading"
          @click="confirmTeamMemberRemoval"
        >
          <span v-if="loading">Declining...</span>
          <span v-else>Confirm</span>
        </button>
      </template>
    </ConfirmationModal>
    <transition name="fade">
      <div
        v-if="showRoleModal"
        class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 px-4"
      >
        <div
          class="bg-white dark:bg-gray-800 w-full max-w-md rounded-lg shadow-lg p-6 space-y-4"
        >
          <h3 class="text-lg font-semibold text-gray-900 dark:text-gray-100">
            Change the role for <strong>{{ selectedUser?.name }}</strong>
          </h3>

          <p class="text-sm text-gray-600 dark:text-gray-300">
            Current Role -
            <strong>{{ toTitleCase(selectedUser?.role) }}</strong>
          </p>
          <div>
            <label
              for="roleSelect"
              class="block text-sm font-medium text-gray-700 dark:text-gray-300"
            >
              Select new role <span class="text-red-500">*</span>
            </label>
            <select
              id="roleSelect"
              v-model="selectedRole"
              class="mt-2 block w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500 border-gray-300 dark:bg-gray-700 dark:text-gray-200 dark:focus:ring-blue-400 dark:focus:border-blue-400"
            >
              <option disabled value="">Select...</option>
              <option
                v-for="role in AVAILABLE_ROLES.filter(
                  (r) => r.name.toLowerCase() !== selectedUser?.role,
                )"
                :key="role.key"
                :value="role.name"
              >
                {{ role.name }} - {{ role.description }}
              </option>
            </select>
          </div>
          <div class="flex justify-end space-x-2">
            <button
              class="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
              @click="closeRoleModal"
            >
              Cancel
            </button>
            <button
              class="px-4 py-2 rounded flex items-center justify-center bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-400 dark:disabled:bg-gray-600"
              :disabled="loading || !selectedRole"
              @click="handleSave"
            >
              <span
                v-if="loading"
                class="animate-spin h-4 w-4 border-2 border-t-2 border-white rounded-full mr-2"
              ></span>
              <span v-if="loading">Saving...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div></transition
    >
  </div>
  <div v-else class="m-2 text-xs md:text-sm text-gray-500 dark:text-gray-300">
    You dont have access to teams member
  </div>
</template>

<script setup>
import { useTeamStore } from '@/stores/teamStore';
import Api from '@/utils/ApiClient';
import { Pencil, UserRoundX } from 'lucide-vue-next';
import { ref, watch } from 'vue';
import { toast } from 'vue3-toastify';
import { AVAILABLE_ROLES } from '@/utils/constantData';
import { toTitleCase } from '@/utils/helperFunctions';
import ConfirmationModal from '@/components/ConfirmationModal.vue';
import { useUserStore } from '@/stores/userStore';
import { storeToRefs } from 'pinia';

const teamStore = useTeamStore();
const userStore = useUserStore();
const currentTeam = ref(teamStore.current_team);
const { members } = storeToRefs(teamStore);
const userToDelete = ref();
const showConfirmationModal = ref(false);
const showRoleModal = ref(false);
const loading = ref(false);
const selectedUser = ref(null);
const selectedRole = ref('');
const selfLeave = ref(false);
const manageRole = async (user, role) => {
  try {
    loading.value = true;
    const response = await Api.patch(`/team/members/${user.id}`, {
      role: role.toLowerCase(),
    });
    if (response.data) {
      toast.success(response.data.message || 'Role updated successfully!');
      teamStore.fetchMembers();
    }
  } catch (error) {
    toast.error(error.response.data || 'Failed to update role');
  } finally {
    loading.value = false;
    showRoleModal.value = false;
  }
};

const openRoleModal = (user) => {
  selectedUser.value = user;
  selectedRole.value = '';
  showRoleModal.value = true;
};
const closeRoleModal = () => {
  showRoleModal.value = false;
  selectedUser.value = null;
  selectedRole.value = '';
};

const handleSave = async () => {
  if (selectedUser.value && selectedRole.value !== selectedUser.value.role) {
    await manageRole(selectedUser.value, selectedRole.value);
  }
};

const confirmRemove = async (user) => {
  if (userStore.userData.id === user.id) {
    selfLeave.value = true;
  } else {
    selfLeave.value = false;
  }
  showConfirmationModal.value = true;
  userToDelete.value = user;
};
const confirmTeamMemberRemoval = async () => {
  try {
    loading.value = true;
    const response = await Api.delete(
      `/team/members/${userToDelete.value?.id}`,
      {
        headers: {
          team_id: currentTeam.value.id,
        },
      },
    );
    if (response.data.message) {
      toast.success(response.data.message || 'Member removed successfully!');
      teamStore.fetchMembers();
    }
  } catch (error) {
    toast.error(error.response.data.detail || 'Member removal failed');
  } finally {
    loading.value = false;
    showConfirmationModal.value = false;
    userToDelete.value = null;
  }
};
watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam) {
      currentTeam.value = newTeam;
      teamStore.fetchMembers();
    }
  },
);
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
