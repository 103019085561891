<!-- src/components/PaginatedTable.vue -->
<template>
  <div
    class="flex items-center pt-4 px-4 pb-2 flex-col bg-white dark:bg-gray-950 border rounded-lg dark:border-gray-700"
  >
    <div class="flex justify-between items-baseline flex-wrap w-full">
      <div class="text-base md:text-xl font-semibold dark:text-white">
        {{ title }} <span class="text-sm md:text-lg">({{ subtitle }})</span>
      </div>
      <div
        class="flex text-sm items-center text-gray-500 dark:text-gray-300 px-4 gap-1"
      >
        <Calendar :size="16" />
        {{ formattedStartDate }} - {{ formattedEndDate }}
      </div>
    </div>

    <table v-if="!loading" class="min-w-full bg-white mt-4 dark:bg-black">
      <thead>
        <tr
          class="border-b border-gray-200 text-gray-600 dark:text-gray-300 text-left text-sm"
        >
          <th class="p-2 pl-6">Rank</th>
          <th class="p-2">{{ column1Title }}</th>
          <th class="p-2">{{ column2Title }}</th>
          <th v-if="displayIndex.length > 2">{{ column3Title }}</th>
          <th v-if="change">Change</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-if="apiData.length"
          v-for="(item, index) in displayedData"
          :key="index"
          class="text-gray-800 dark:text-gray-200 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors border-b border-gray-200 text-xs md:text-base"
        >
          <td class="p-2 pl-8">{{ startIndex + index + 1 }}</td>
          <td class="p-2">{{ item[Number(displayIndex[0])] }}</td>
          <td class="p-2 indent-4">
            {{
              formatter
                ? formatter(item[Number(displayIndex[1])])
                : item[Number(displayIndex[1])]
            }}
          </td>
          <td v-if="displayIndex.length > 2">
            {{
              formatter
                ? formatter(item[Number(displayIndex[2])])
                : item[Number(displayIndex[2])]
            }}
          </td>
          <td v-if="change" class="text-sm">
            <span
              v-if="item[change] > 0"
              class="text-green-600 flex items-center"
              ><ArrowUp :size="16" />{{ item[change] }}%</span
            >
            <span v-else class="text-red-500 flex items-center"
              ><ArrowDown :size="16" />{{ item[change] }}%</span
            >
          </td>
        </tr>
        <tr v-else>
          <td></td>

          <td class="indent-2">No activity.</td>
        </tr>
      </tbody>
    </table>
    <div v-else class="flex flex-grow justify-center">
      <StyledLoader />
    </div>
    <div class="flex justify-between mt-4 text-sm self-end gap-1">
      <button
        class="px-2 py-2 border text-black dark:text-white rounded-md"
        @click="prevPage"
        :disabled="currentPage === 1"
      >
        Previous
      </button>
      <span class="self-center text-black dark:text-white"
        >Page {{ currentPage }} of {{ totalPages ? totalPages : 0 }}</span
      >
      <button
        class="px-2 py-2 border text-black dark:text-white rounded-md"
        @click="nextPage"
        :disabled="currentPage === totalPages"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick } from 'vue';
import Api from '@/utils/ApiClient';
import { useTeamStore } from '@/stores/teamStore';
import StyledLoader from '@/components/StyledLoader.vue';
import { ArrowDown, ArrowUp, Calendar } from 'lucide-vue-next';
import { useDateStore } from '@/stores/dateStore';

const teamStore = useTeamStore();
const loading = ref(false);
const currentTeam = ref(teamStore.current_team);
const dateStore = useDateStore();
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
    deep: true,
  },
);
const props = defineProps({
  title: String,
  subtitle: String,
  column1Title: String,
  column2Title: String,
  column3Title: String,
  apiUrl: String,
  formatter: Function,
  fromDate: String,
  toDate: String,
  displayIndex: Array,
  change: Number,
});

const itemsPerPage = 5;
const currentPage = ref(1);
const apiData = ref([]);
const fetchData = async () => {
  try {
    loading.value = true;
    const params = dateStore.isComparing
      ? {
          team_id: currentTeam.value?.id,
          start_date: dateStore?.insightDate?.currentStart,
          end_date: dateStore?.insightDate?.currentEnd,
          past_start_date: dateStore?.insightDate?.pastStart,
          past_end_date: dateStore?.insightDate?.pastEnd,
        }
      : {
          team_id: currentTeam.value?.id,
          start_date: dateStore?.insightDate?.currentStart,
          end_date: dateStore?.insightDate?.currentEnd,
        };
    const response = await Api.get(props.apiUrl, {
      params: params,
    });
    apiData.value = JSON.parse(response.data?.items.data);
  } catch (error) {
    apiData.value = [];
  } finally {
    loading.value = false;
  }
};

watch(
  () => [dateStore.insightDate, dateStore.isComparing],
  async () => {
    await nextTick();
    await fetchData();
  },
  { immediate: true, deep: true },
);

const totalPages = computed(() =>
  Math.ceil(apiData.value?.length / itemsPerPage),
);
const startIndex = computed(() => (currentPage.value - 1) * itemsPerPage);

const displayedData = computed(() =>
  apiData.value?.slice(startIndex.value, startIndex.value + itemsPerPage),
);

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};
const formattedStartDate = computed(() => {
  const options = { year: 'numeric', month: 'short' };
  return new Date(
    dateStore.isComparing
      ? dateStore?.insightDate?.pastStart
      : dateStore?.insightDate?.currentStart,
  ).toLocaleDateString('en-US', options);
});

const formattedEndDate = computed(() => {
  const options = { year: 'numeric', month: 'short', year: 'numeric' };
  return new Date(dateStore?.insightDate?.currentEnd).toLocaleDateString(
    'en-US',
    options,
  );
});
</script>
