import { defineStore } from 'pinia';

export const useConnectorStore = defineStore('connector', {
  state: () => ({
    loading: false,
    recentFetchPending: false,
    completedConnectors: {},
    connectorId: {},
  }),
  actions: {
    setLoading() {
      this.loading = !this.loading;
    },
    setRecentFetchPending() {
      this.recentFetchPending = !this.recentFetchPending;
    },
    setCompleted(name, status) {
      this.completedConnectors[name] = status;
    },
    setConnectorId(name, id) {
      this.connectorId[name] = id;
    },

    clearCompleted() {
      this.completedConnectors = {};
    },
    clearId() {
      this.connectorId = {};
    },

    clearStore() {
      this.loading = false;
      this.recentFetchPending = false;
      this.completedConnectors = {};
      this.connectorId = {};
    },
  },
});
