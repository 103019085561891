<template>
  <MetricCard
    apiEndpoint="/google-ads/cost-per-click"
    metricName="CPC"
    :formatData="formatCPC"
    srcName="Google Ads"
  />
</template>

<script setup>
import MetricCard from '@/components/MetricCard.vue';

const formatCPC = (data) => '₹' + data.data;
</script>
