<template>
  <MetricCard
    apiEndpoint="/linkedin/return-on-ad-spend"
    metricName="ROAS"
    :formatData="formatData"
    srcName="LinkedIn Ads"
  />
</template>

<script setup>
import { formatNumber } from '@/utils/helperFunctions';
import MetricCard from '@/components/MetricCard.vue';

const formatData = (data) => '₹' + formatNumber(data.data);
</script>
