<template>
  <MetricCard
    apiEndpoint="/youtube-analytics/engagement-rate"
    metricName="Engagement Rate"
    :formatData="formatData"
    srcName="YouTube Analytics"
  />
</template>

<script setup>
import MetricCard from '@/components/MetricCard.vue';
const formatData = (data) => data.data + '%';
</script>
