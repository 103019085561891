<template>
  <MetricCard
    apiEndpoint="/blinkit/cpm"
    metricName="CPM"
    :formatData="formatData"
    srcName="Blinkit"
  />
</template>

<script setup>
import MetricCard from '@/components/MetricCard.vue';
const formatData = (data) => '₹' + data.data;
</script>
