<template>
  <div class="flex-1 dark:bg-black bg-gray-100 min-h-lvh">
    <SocialCallbackCard :data="data" />
  </div>
</template>
<script setup>
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import SocialCallbackCard from '@/components/connectors/callback_cards/SocialCallbackCard.vue';
const route = useRoute();

const platformMap = {
  instagram: { sourceType: 'instagram', name: 'Instagram ' },
  'tiktok-marketing': { sourceType: 'tiktok-marketing', name: 'TikTok ' },
};

const data = computed(() => {
  const platform = route.params.platform;
  return (
    platformMap[platform] || {
      sourceType: 'Unknown',
      name: 'Unknown Connector',
    }
  );
});
</script>
