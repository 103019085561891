<template>
  <MetricCard
    apiEndpoint="/facebook/click-through-rate"
    metricName="CTR"
    :formatData="formatData"
    srcName="Facebook Ads"
  />
</template>

<script setup>
import MetricCard from '@/components/MetricCard.vue';
const formatData = (data) => data.data + '%';
</script>
