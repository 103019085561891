<template>
  <FormSection @submitted="createTeam">
    <template #title> Team Details </template>

    <template #description>
      Create a new team to collaborate with others on projects.
    </template>

    <template #form>
      <div class="col-span-6">
        <InputLabel value="Team Owner" />

        <div class="flex items-center mt-2">
          <div class="ms-4 leading-tight">
            <div class="text-gray-900 dark:text-gray-200">{{ user?.name }}</div>
            <div class="text-sm text-gray-700 dark:text-gray-200">
              {{ user?.email }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-6 sm:col-span-4">
        <InputLabel for="name" value="Team Name" />
        <TextInput
          id="name"
          v-model="form.name"
          type="text"
          class="block w-full mt-1"
          autofocus
        />
        <InputError :message="errors.name" class="mt-2" />
      </div>
    </template>

    <template #actions>
      <PrimaryButton
        :class="{ 'opacity-25': form.processing }"
        :disabled="form.processing"
      >
        Create
      </PrimaryButton>
    </template>
  </FormSection>
</template>

<script setup>
import { computed, ref } from 'vue';
import InputLabel from '@/components/InputLabel.vue';
import TextInput from '@/components/TextInput.vue';
import InputError from '@/components/InputError.vue';
import PrimaryButton from '@/components/PrimaryButton.vue';
import FormSection from '@/components/FormSection.vue';
import { useUserStore } from '@/stores/userStore';
import Api from '@/utils/ApiClient';
import { toast } from 'vue3-toastify';
import { useTeamStore } from '@/stores/teamStore';

const form = ref({
  name: '',
  processing: false,
});
const userStore = useUserStore();
const user = computed(() => {
  return {
    name:
      userStore?.userData?.first_name + ' ' + userStore?.userData?.last_name,
    email: userStore?.userData?.email,
  };
});
const teamStore = useTeamStore();
const errors = ref({ name: '' });

const validateForm = () => {
  errors.value.name = '';
  let isValid = true;

  if (!form.value.name.trim()) {
    errors.value.name = 'Team name is required.';
    isValid = false;
  } else if (form.value.name.length < 3) {
    errors.value.name = 'Team name must be at least 3 characters long.';
    isValid = false;
  }

  return isValid;
};

const createTeam = async () => {
  if (!validateForm()) {
    return;
  }

  form.value.processing = true;

  try {
    const response = await Api.post('/team', {
      team_name: form.value.name,
    });

    if (response.status === 201) {
      teamStore.refetchNewTeamsFlag = true;
      toast.success('Team Created successfully.');
    }
  } catch (error) {
    toast.error('Team creation failed');
  } finally {
    form.value.processing = false;
    form.value.name = '';
  }
};
</script>
