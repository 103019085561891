<template>
  <div
    :class="[
      'p-2 rounded-lg shadow-md transition duration-300 pt-3 h-full dark:bg-black-600 grid grid-rows-2',
      connectorStore.loading && !loading ? 'opacity-50' : '',
      bgClass,
    ]"
  >
    <div class="flex justify-center items-center p-2 gap-2">
      <div
        class="flex items-center justify-center size-10 bg-white rounded-full shadow-sm border border-gray-300 dark:border-gray-600"
      >
        <img
          :src="iconSrc"
          :alt="name"
          class="size-6 object-contain mix-blend-darken"
          v-if="iconSrc"
        />
      </div>

      <div class="font-inter font-medium text-gray-800">
        {{ name }}
      </div>
    </div>
    <div class="h-full">
      <div
        v-if="completed"
        class="text-black text-center lg:text-sm text-xs flex justify-center items-center p-2"
      >
        Account Connected
      </div>
      <div v-else class="flex">
        <div class="flex-1 flex justify-center items-center">
          <div v-if="loading" class="mt-2"><StyledLoader /></div>

          <div v-else>
            <button
              @click="continueConection"
              v-if="partiallyComplete"
              class="bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 text-white font-semibold py-3 px-4 rounded-lg transition duration-300 text-wrap w-full text-xs overflow-hidden lg:text-sm"
              :disabled="connectorStore.loading"
            >
              Continue Connecting
            </button>
            <button
              v-else
              @click="createConnection"
              class="bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 text-white font-semibold py-3 px-4 rounded-lg transition duration-300 text-wrap w-full text-xs overflow-hidden lg:text-sm"
              :disabled="connectorStore.loading"
            >
              Connect your account
            </button>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal
      :show="showRegionModal"
      @close="showRegionModal = false"
      imgSrc="/images/amazon.png"
    >
      <template #title
        >Select your country to continue connecting Amazon Ads account</template
      >
      <template #content>
        <div class="p-4">
          <select
            v-model="region"
            class="w-full p-2 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <option value="" disabled>Select your country</option>
            <option
              v-for="(regionCode, country) in AMAZON_ACCOUNT_REGION"
              :key="country"
              :value="regionCode"
            >
              {{ country }}
            </option>
          </select>
        </div>
      </template>
      <template #footer>
        <button
          @click="submitRegion"
          :disabled="!region"
          class="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300"
        >
          Continue
        </button>
        <button
          @click="showRegionModal = false"
          class="ml-2 bg-gray-300 hover:bg-gray-400 text-black font-semibold py-2 px-4 rounded-lg transition duration-300"
        >
          Cancel
        </button>
      </template>
    </ConfirmationModal>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { toast } from 'vue3-toastify';
import StyledLoader from '@/components/StyledLoader.vue';
import Api from '@/utils/ApiClient';
import { useTeamStore } from '@/stores/teamStore';
import { useConnectorStore } from '@/stores/connectorStore';
import { useRouter } from 'vue-router';
import ConfirmationModal from '../ConfirmationModal.vue';
import { AMAZON_ACCOUNT_REGION } from '@/utils/constantData';
const props = defineProps(['data']);
const { sourceType, name } = props.data;
const router = useRouter();
const teamStore = useTeamStore();
const connectorStore = useConnectorStore();
const currentTeam = ref(teamStore.current_team);
const loading = ref(false);
const showRegionModal = ref(false);
const region = ref('');
const completed = computed(
  () =>
    connectorStore.completedConnectors[sourceType] &&
    !!connectorStore.connectorId[sourceType],
);
const partiallyComplete = computed(
  () =>
    connectorStore.completedConnectors[sourceType] &&
    !connectorStore.connectorId[sourceType],
);

const bgClass = computed(() => {
  if (completed.value) {
    return 'bg-green-200';
  } else if (partiallyComplete.value) {
    return 'bg-yellow-100';
  }
  return 'bg-gray-200';
});
const iconSrc = computed(() => {
  const icons = {
    'google-ads': '/images/google-ads.png',
    'facebook-marketing': '/images/facebook_ads.png',
    'google-analytics-data-api': '/images/google_analytics_4.png',
    'amazon-ads': '/images/amazon.png',
    'google-search-console': '/images/google-search-console.png',
    instagram: '/images/instagram.png',
    'youtube-analytics': '/images/youtube.png',
    'tiktok-marketing': '/images/tiktok.png',
    'linkedin-ads': '/images/linkedin.png',
    'linkedin-pages': '/images/linkedin.png',
    'amazon-seller-partner': '/images/amazon_sp.png',
  };
  return icons[sourceType] || null;
});

const submitRegion = async () => {
  if (!region.value) {
    toast.error('Please select a country before proceeding.');
    return;
  }

  showRegionModal.value = false;
  loading.value = true;
  try {
    const response = await Api.get(`/airbyte/initiate-connection`, {
      params: {
        source_type: sourceType,
        account_region: region.value,
      },
    });
    window.location.href = response.data;
  } catch (error) {
    toast.error(error.response.data.detail);
  } finally {
    loading.value = false;
  }
};

const createConnection = async () => {
  if (sourceType === 'amazon-ads') {
    showRegionModal.value = true;
    return;
  }

  try {
    connectorStore.setLoading();
    loading.value = true;
    if (sourceType === 'youtube-analytics') {
      const response = await Api.get(`/airbyte/youtube-login`);
      if (response.data) {
        window.location.href = response.data;
      }
    } else if (sourceType === 'linkedin-pages') {
      const response = await Api.get(`/airbyte/linkedin-page-login`);
      if (response.data) {
        window.location.href = response.data;
      }
    } else {
      const response = await Api.get(`/airbyte/initiate-connection`, {
        params: {
          source_type: sourceType,
        },
      });
      if (response.data) {
        window.location.href = response.data;
      }
    }
  } catch (error) {
    toast.error(error.response.data.detail);
  } finally {
    connectorStore.setLoading();
    loading.value = false;
  }
};
const continueConection = () => {
  router.push(`/connector/${sourceType}`);
};
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  { immediate: true },
);
</script>

<style scoped></style>
