<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-4 px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 min-h-full flex-grow max-h-[420px] md:max-h-[500px]"
  >
    <!-- Header section with title -->
    <div class="flex justify-between flex-wrap">
      <div class="text-base md:text-xl font-semibold mb-2 dark:text-white">
        Country Distribution
      </div>
      <div
        class="flex text-sm items-center text-gray-500 dark:text-gray-300 px-4 gap-1 mb-2"
      >
        <Calendar :size="16" />
        {{ formattedStartDate }} - {{ formattedEndDate }}
      </div>
    </div>
    <div v-if="loading" class="flex flex-1 justify-center">
      <StyledLoader />
    </div>
    <div v-else class="flex-1 grid">
      <div
        v-if="data.length"
        class="flex justify-center items-center flex-col lg:flex-row"
      >
        <apexchart
          type="bar"
          :options="chartOptions"
          :series="chartSeries"
          class="w-full"
        ></apexchart>
        <div
          class="text-xs md:text-base lg:text-lg flex lg:flex-col gap-2 md:gap-0 flex-wrap justify-center"
        >
          <span
            v-for="(item, index) in labelData"
            :key="index"
            :style="{ color: colors[index] }"
          >
            ● {{ item }}
          </span>
        </div>
      </div>
      <div v-else class="flex justify-center items-center text-base md:text-lg">
        No activity.
      </div>
    </div>
    <SourceBadge
      src="/images/youtube.png"
      alt="YouTube Analytics"
      label="YouTube Analytics"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import Api from '@/utils/ApiClient';
import { useTeamStore } from '@/stores/teamStore';
import { formatNumber } from '@/utils/helperFunctions';
import { useDateStore } from '@/stores/dateStore';
import SourceBadge from '@/components/SourceBadge.vue';
import StyledLoader from '@/components/StyledLoader.vue';
import { Calendar } from 'lucide-vue-next';

const teamStore = useTeamStore();
const dateStore = useDateStore();
const currentTeam = ref(teamStore.current_team);
const data = ref();
const labelData = ref();
const loading = ref(false);

const chartSeries = ref([
  {
    name: 'Distribution',
    data: [], // Values for the bar chart
  },
]);
const chartLabels = ref([]);

const colors = [
  '#60a5fa',
  '#FF69B4',
  '#FF7F50',
  '#4CAF50 ',
  '#FF9800',
  '#2196F3',
  '#9C27B0 ',
  '#FFEB3B',
];
const chartOptions = computed(() => ({
  chart: {
    id: 'country-chart',
    type: 'bar',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  colors: colors,
  xaxis: {
    categories: chartLabels.value,
  },
  legend: {
    show: false,
  },
  tooltip: {
    theme: 'dark',
  },
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '40%',
    },
  },
}));

const formattedStartDate = computed(() => {
  const options = { year: 'numeric', month: 'short' };
  return new Date(
    dateStore.isComparing
      ? dateStore?.insightDate?.pastStart
      : dateStore?.insightDate?.currentStart,
  ).toLocaleDateString('en-US', options);
});

const formattedEndDate = computed(() => {
  const options = { year: 'numeric', month: 'short', year: 'numeric' };
  return new Date(dateStore?.insightDate?.currentEnd).toLocaleDateString(
    'en-US',
    options,
  );
});

const fetchData = async () => {
  try {
    loading.value = true;
    const params = dateStore.isComparing
      ? {
          start_date: dateStore?.insightDate?.currentStart,
          end_date: dateStore?.insightDate?.currentEnd,
          past_start_date: dateStore?.insightDate?.pastStart,
          past_end_date: dateStore?.insightDate?.pastEnd,
        }
      : {
          start_date: dateStore?.insightDate?.currentStart,
          end_date: dateStore?.insightDate?.currentEnd,
        };
    const response = await Api.get(`/youtube-analytics-insights/country`, {
      params: params,
    });

    data.value = JSON.parse(response.data?.items.data);
    if (Array.isArray(data.value) && data?.value?.length > 0) {
      const labels = data.value.map((items) => items[0]);
      const series = data.value.map((item) => item[1]);
      labelData.value = data.value.map(
        (item) => item[0].toUpperCase() + '-' + formatNumber(item[1]),
      );
      chartLabels.value = labels;
      chartSeries.value = [
        {
          name: 'Distribution',
          data: series,
        },
      ];
    }
  } catch (error) {
    data.value = [];
    chartLabels.value = [];
    chartSeries.value = [];
    labelData.value = [];
  } finally {
    loading.value = false;
  }
};

watch(
  () => [dateStore.insightDate, dateStore.isComparing],
  async () => {
    await fetchData();
  },
  { immediate: true, deep: true },
);

watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam) {
      currentTeam.value = newTeam;
      fetchData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
