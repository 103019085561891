<template>
  <MetricCard
    apiEndpoint="/blinkit/impressions"
    metricName="IMPRESSION"
    :formatData="formatData"
    srcName="Blinkit"
  />
</template>

<script setup>
import { formatNumber } from '@/utils/helperFunctions';
import MetricCard from '@/components/MetricCard.vue';
const formatData = (data) => formatNumber(data.data);
</script>
