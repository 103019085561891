<template>
  <MetricCard
    apiEndpoint="/google-analytics/engaged-sessions"
    metricName="Engaged Sessions"
    :formatData="formatUsers"
    srcName="Google Analytics"
  />
</template>

<script setup>
import { formatNumber } from '@/utils/helperFunctions';
import MetricCard from '@/components/MetricCard.vue';

const formatUsers = (data) => formatNumber(data.data);
</script>
