<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-2 px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 h-full"
  >
    <div class="text-base md:text-lg">CPC VS CTR</div>

    <div v-if="loading" class="flex flex-1 justify-center">
      <StyledLoader />
    </div>
    <div v-else class="flex-1 grid">
      <div
        v-if="error"
        class="flex justify-center items-center text-base md:text-lg"
      >
        No Activity.
      </div>
      <div
        class="my-2 mx-auto flex-grow flex justify-center items-center"
        v-else
      >
        <apexchart
          type="line"
          :options="chartOptions"
          :series="chartSeries"
          class="flex-1"
        ></apexchart>
      </div>
    </div>
    <SourceBadge
      src="/images/google-ads.png"
      alt="Google Ads"
      label="Google Ads"
    />
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import Api from '@/utils/ApiClient';
import { useTeamStore } from '@/stores/teamStore';
import StyledLoader from '@/components/StyledLoader.vue';
import SourceBadge from '@/components/SourceBadge.vue';
import { useDateStore } from '@/stores/dateStore';

const teamStore = useTeamStore();
const dateStore = useDateStore();
const categories = ref([]);
const data = ref([]);
const error = ref(false);
const loading = ref(false);
const chartSeries = ref([
  {
    name: 'CPC',
    data: [],
  },
  {
    name: 'CTR',
    data: [],
  },
]);

const fetchData = async () => {
  try {
    loading.value = true;
    const response = await Api.get(`/google-ads/cpc-and-ctr`, {
      params: {
        start_date: dateStore.dashboardDate.currentStart,
        end_date: dateStore.dashboardDate.currentEnd,
      },
    });
    if (response.data?.items) {
      error.value = false;
      data.value = JSON.parse(response.data?.items.data);
      processData();
    } else {
      error.value = true;
      data.value = [];
      categories.value = [];
      chartSeries.value = [
        {
          name: 'CPC',
          data: [],
        },
        {
          name: 'CTR',
          data: [],
        },
      ];
    }
  } catch (error) {
    error.value = true;
    data.value = [];
    categories.value = [];
    chartSeries.value = [
      {
        name: 'Impressions',
        data: [],
      },
      {
        name: 'Clicks',
        data: [],
      },
    ];
  } finally {
    loading.value = false;
  }
};

const processData = () => {
  const rawData = data.value.map((item) => [
    item[0],
    item[1],
    new Date(item[2]),
  ]);

  rawData.sort((a, b) => a[2] - b[2]);

  const startDate = rawData[0][2];
  const endDate = rawData[rawData.length - 1][2];

  const dateDiffInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
  let groupBy = 'day';
  if (dateDiffInDays >= 90) groupBy = 'month';
  else if (dateDiffInDays >= 30) groupBy = 'week';

  const groupedData = {};

  rawData.forEach((item) => {
    const timestamp = item[2];
    let groupKey;
    let groupDate;

    if (groupBy === 'month') {
      groupDate = new Date(timestamp.getFullYear(), timestamp.getMonth(), 1);
      groupKey = `${groupDate.getFullYear()}-${groupDate.getMonth() + 1}`;
    } else if (groupBy === 'week') {
      const weekStart = new Date(timestamp);
      weekStart.setDate(weekStart.getDate() - weekStart.getDay());
      groupDate = new Date(weekStart);
      groupKey = weekStart.toISOString().split('T')[0];
    } else {
      groupDate = timestamp;
      groupKey = groupDate.toISOString().split('T')[0];
    }

    if (!groupedData[groupKey]) {
      groupedData[groupKey] = {
        rawDate: groupDate,
        cpcSum: 0,
        ctrSum: 0,
        count: 0,
      };
    }

    groupedData[groupKey].cpcSum += item[0];
    groupedData[groupKey].ctrSum += item[1];
    groupedData[groupKey].count += 1;
  });

  categories.value = Object.values(groupedData).map((item) => {
    const timestamp = new Date(item.rawDate);

    if (groupBy === 'month') {
      return timestamp.toLocaleDateString('en-GB', {
        month: 'short',
        year: 'numeric',
      });
    }

    if (groupBy === 'week') {
      const weekStart = new Date(item.rawDate);
      const weekEnd = new Date(item.rawDate);

      weekStart.setDate(weekStart.getDate() - weekStart.getDay());
      weekEnd.setDate(weekStart.getDate() + 6);

      return `${weekStart.getDate()} ${weekStart.toLocaleDateString('en-GB', {
        month: 'short',
      })} - ${weekEnd.getDate()} ${weekEnd.toLocaleDateString('en-GB', {
        month: 'short',
        year: '2-digit',
      })}`;
    }

    return timestamp.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
    });
  });

  chartSeries.value = [
    {
      name: 'CPC',
      data: Object.values(groupedData).map((item) =>
        (item.cpcSum / item.count).toFixed(2),
      ),
    },
    {
      name: 'CTR',
      data: Object.values(groupedData).map((item) =>
        (item.ctrSum / item.count).toFixed(2),
      ),
    },
  ];
};

const chartOptions = computed(() => ({
  chart: {
    id: 'cpc-and-ctr-chart',
    type: 'line',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  colors: ['#3b82f6', '#a855f7'],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  xaxis: {
    categories: categories.value,
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  grid: {
    show: false,
  },
  tooltip: {
    x: {
      formatter: function (value, { seriesIndex, dataPointIndex }) {
        const category = categories.value[dataPointIndex];
        return `${category}`;
      },
    },
    theme: 'dark',
  },
  noData: {
    text: 'No Activity',
  },
  legend: {
    position: 'bottom',
    labels: {
      useSeriesColors: true,
    },
  },
}));

watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam.id) {
      fetchData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);

watch(
  () => dateStore.dashboardDate,
  () => {
    fetchData();
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
