<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-2 px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 min-h-full flex-grow"
  >
    <div class="">Engagement VS Reach</div>

    <div v-if="loading" class="flex flex-1 justify-center">
      <StyledLoader />
    </div>
    <div v-else class="flex-1 grid">
      <div
        class="my-2 mx-auto flex-grow flex justify-center items-center"
        v-if="data.length"
      >
        <apexchart
          type="line"
          :options="chartOptions"
          :series="chartSeries"
          class="flex-1"
        ></apexchart>
      </div>
      <div v-else class="flex justify-center items-center text-base md:text-lg">
        No activity.
      </div>
    </div>
    <SourceBadge
      src="/images/instagram.png"
      alt="Instagram"
      label="Instagram"
    />
  </div>
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import Api from '@/utils/ApiClient';
import StyledLoader from '@/components/StyledLoader.vue';
import SourceBadge from '@/components/SourceBadge.vue';
import { useTeamStore } from '@/stores/teamStore';
import { useDateStore } from '@/stores/dateStore';
import {
  formatChartLabel,
  formatNumber,
  groupChartData,
} from '@/utils/helperFunctions';

const teamStore = useTeamStore();
const dateStore = useDateStore();
const data = ref([]);
const categories = ref([]);
const chartOptions = computed(() => ({
  chart: {
    id: 'engagement-reach-chart',
    type: 'line',
    toolbar: {
      show: false,
    },
    zoom: { enabled: false },
  },
  colors: ['#bc2a8d', '#29bc8d'],
  stroke: { curve: 'smooth', width: 2 },
  xaxis: {
    categories: categories.value,
    labels: {
      show: false,
    },
  },
  yaxis: {
    labels: { show: false },
  },
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex }) {
      const actualDataPoint = data.value[dataPointIndex];
      const date = new Date(actualDataPoint[2]).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
      });
      if (actualDataPoint) {
        return `<div class="tooltip   text-white">
                  <p class='text-center bg-black py-2   '>${date}</p>
                  <p class='py-1 px-3'>Engagement: ${actualDataPoint[0]}</p>
                  <p class='py-1 px-3'>Reach: ${formatNumber(actualDataPoint[1])}</p>
                  
                </div>`;
      }
      return '<div>No data available</div>';
    },
    theme: 'dark',
  },
  grid: { show: false },
  legend: {
    position: 'bottom',
    labels: { useSeriesColors: true },
  },
}));

const chartSeries = ref([
  { name: 'Engagement', data: [] },
  { name: 'Reach', data: [] },
]);

const loading = ref(false);

const fetchData = async () => {
  try {
    loading.value = true;
    const response = await Api.get(`/instagram/engagement-and-reach`, {
      params: {
        start_date: dateStore.dashboardDate.currentStart,
        end_date: dateStore.dashboardDate.currentEnd,
      },
    });

    if (response.data) {
      const { chart_data, actual_data } = response.data.items;
      const parsedChartData = chart_data && JSON.parse(chart_data);
      const parsedActualData = actual_data && JSON.parse(actual_data);
      if (parsedActualData && parsedChartData) {
        const rawData = parsedActualData;
        const startDate = new Date(rawData[0][2]);
        const endDate = new Date(rawData[rawData.length - 1][2]);
        const dateDiffInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
        let groupBy = 'day';
        if (dateDiffInDays > 90) groupBy = 'month';
        else if (dateDiffInDays > 30) groupBy = 'week';
        const groupedData = groupChartData(rawData, groupBy, [0, 1]);
        categories.value = groupedData.map((item) =>
          formatChartLabel(item.rawDate, groupBy),
        );
        chartSeries.value = [
          {
            name: 'Engagement',
            data: groupedData.map((item) => item.values[0]),
          },
          { name: 'Reach', data: groupedData.map((item) => item.values[1]) },
        ];
        data.value = parsedActualData;
      }
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    chartSeries.value = [
      { name: 'Engagement', data: [] },
      { name: 'Reach', data: [] },
    ];
    categories.value = [];
    data.value = [];
  } finally {
    loading.value = false;
  }
};

watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam) fetchData();
  },
  { immediate: true, deep: true },
);

watch(() => dateStore.dashboardDate, fetchData, {
  immediate: true,
  deep: true,
});
</script>
