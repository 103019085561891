<template>
  <transition name="fade">
    <div
      v-if="isModalOpen"
      class="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex items-center justify-center px-4"
    >
      <div class="bg-white dark:bg-gray-800 p-6 rounded-lg w-full max-w-md">
        <!-- Modal Header -->
        <div class="flex justify-between items-center mb-4">
          <h3 class="text-lg font-semibold dark:text-white">
            Select Date Range
          </h3>
          <button
            @click="closeModal"
            class="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
          >
            <X />
          </button>
        </div>

        <!-- Date Range Inputs -->
        <div class="mb-4">
          <div class="flex flex-col sm:flex-row gap-2">
            <div class="flex-1">
              <label class="dark:text-white">From:</label>
              <input
                type="date"
                v-model="localStartDate"
                @change="onDateChange"
                class="border border-gray-300 rounded-lg px-3 py-2 dark:bg-gray-900 dark:text-white dark:border-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
              />
            </div>
            <div class="flex-1">
              <label class="dark:text-white">To:</label>
              <input
                type="date"
                v-model="localEndDate"
                @change="onDateChange"
                class="border border-gray-300 rounded-lg px-3 py-2 dark:bg-gray-900 dark:text-white dark:border-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
              />
            </div>
          </div>
        </div>

        <!-- Checkbox for Compare -->
        <div
          class="flex items-center justify-around mb-4"
          v-if="compareRequire"
        >
          <label
            for="compare-checkbox"
            class="text-lg font-semibold dark:text-white flex-1"
          >
            Compare
          </label>
          <button
            @click="toggleCompare"
            :class="[
              'relative inline-flex items-center justify-between w-10 h-5 p-1 rounded-full transition-colors duration-300 ease-in-out',
              localIsCompareChecked ? 'bg-blue-500' : 'bg-gray-400',
            ]"
            aria-checked="false"
            role="switch"
          >
            <span
              :class="[
                'block w-5 h-5 bg-white rounded-full shadow-md transform transition-transform duration-300 ease-in-out',
                localIsCompareChecked ? 'translate-x-4' : '-translate-x-1',
              ]"
            ></span>
          </button>
        </div>

        <!-- Additional Date Inputs for Comparison if Checkbox is Checked -->
        <div v-if="localIsCompareChecked" class="mb-4">
          <div class="flex flex-col sm:flex-row gap-2">
            <div class="flex-1">
              <label class="dark:text-white">Compare From:</label>
              <input
                type="date"
                v-model="localCompareStartDate"
                @change="onDateChange"
                class="border border-gray-300 rounded-lg px-3 py-2 dark:bg-gray-900 dark:text-white dark:border-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
              />
            </div>
            <div class="flex-1">
              <label class="dark:text-white">Compare To:</label>
              <input
                type="date"
                v-model="localCompareEndDate"
                @change="onDateChange"
                class="border border-gray-300 rounded-lg px-3 py-2 dark:bg-gray-900 dark:text-white dark:border-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
              />
            </div>
          </div>
        </div>

        <!-- Presets -->
        <div class="mt-4">
          <p class="dark:text-white">Presets:</p>
          <div class="space-y-2">
            <button
              v-for="preset in presets"
              :key="preset.label"
              @click="applyPreset(preset)"
              class="block w-full text-left px-4 py-2 border rounded dark:bg-gray-950 dark:text-white dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-700"
            >
              {{ preset.label }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, watch } from 'vue';
import { X } from 'lucide-vue-next';
import { toast } from 'vue3-toastify';
import { presets } from '@/utils/constantData';
import { useDateStore } from '@/stores/dateStore';

const emit = defineEmits(['toggleCompare', 'close']);

const props = defineProps({
  isCompareChecked: Boolean,
  isModalOpen: Boolean,
  compareRequire: Boolean,
});

const localStartDate = ref();
const localEndDate = ref();
const localCompareStartDate = ref();
const localCompareEndDate = ref();
const localIsCompareChecked = ref(props.isCompareChecked || false);
const dateStore = useDateStore();
const onDateChange = () => {
  if (validateDateRange(localStartDate.value, localEndDate.value)) {
    if (props.compareRequire) {
      dateStore.setInsightDate('pastStart', localCompareStartDate.value);
      dateStore.setInsightDate('pastEnd', localCompareEndDate.value);
      dateStore.setInsightDate('currentStart', localStartDate.value);
      dateStore.setInsightDate('currentEnd', localEndDate.value);
    } else {
      dateStore.setDashboardDate('currentStart', localStartDate.value);
      dateStore.setDashboardDate('currentEnd', localEndDate.value);
    }
  }
};

const validateDateRange = (start, end) => {
  const today = new Date().toISOString().split('T')[0];

  if (!start || !end) {
    toast.error('Please select both start and end dates.');
    return false;
  }

  if (start > today || end > today) {
    toast.error('Dates cannot be in the future.');
    return false;
  }

  if (end < start) {
    toast.error('End date cannot be earlier than start date.');
    return false;
  }

  return true;
};

const toggleCompare = () => {
  localIsCompareChecked.value = !localIsCompareChecked.value;
  emit('toggleCompare', localIsCompareChecked.value);
};

const applyPreset = (preset) => {
  localStartDate.value = preset.startDate;
  localEndDate.value = preset.endDate;
  onDateChange();
  emit('close');
};

const closeModal = () => {
  emit('close');
};
watch(
  dateStore,
  () => {
    if (props.compareRequire) {
      localStartDate.value = dateStore.insightDate.currentStart;
      localEndDate.value = dateStore.insightDate.currentEnd;
      localCompareStartDate.value = dateStore.insightDate.pastStart;
      localCompareEndDate.value = dateStore.insightDate.pastEnd;
    } else {
      localStartDate.value = dateStore.dashboardDate.currentStart;
      localEndDate.value = dateStore.dashboardDate.currentEnd;
    }
  },
  { immediate: true, deep: true },
);
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
