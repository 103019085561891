<template>
  <div
    class="rounded-lg overflow-hidden bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 flex flex-col px-2 md:px-4 py-4"
  >
    <!-- Header section with title -->
    <div class="flex justify-between flex-wrap">
      <div class="text-base md:text-xl font-semibold mb-2 dark:text-white">
        Channel Overview
      </div>
      <div
        class="flex text-sm items-center text-gray-500 dark:text-gray-300 px-4 gap-1 mb-2"
      >
        <Calendar :size="16" />
        {{ formattedStartDate }} - {{ formattedEndDate }}
      </div>
    </div>
    <div v-if="loading" class="flex flex-1 justify-center">
      <StyledLoader />
    </div>
    <div v-else>
      <div v-if="data.length">
        <apexchart
          type="bar"
          :options="chartOptions"
          :series="chartSeries"
          height="320px"
        ></apexchart>
      </div>
      <div
        v-else
        class="flex justify-center items-center h-80 text-base md:text-lg"
      >
        No activity.
      </div>
    </div>
    <SourceBadge
      src="/images/google_analytics_4.png"
      alt="Google Analytics"
      label="Google Analytics"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import Api from '@/utils/ApiClient';
import { useTeamStore } from '@/stores/teamStore';
import { useDateStore } from '@/stores/dateStore';
import { Calendar } from 'lucide-vue-next';
import StyledLoader from '@/components/StyledLoader.vue';
import { formatNumber } from '@/utils/helperFunctions';
import SourceBadge from '@/components/SourceBadge.vue';
const dateStore = useDateStore();

const chartSeries = ref([
  {
    name: 'Current',
    data: [],
  },
  {
    name: 'Past',
    data: [],
  },
]);
const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
const data = ref();
const chartLabels = ref([]);
const loading = ref(false);
const change = ref([]);

const colors = ['#f59e0b', '#fbbf24'];

const chartOptions = computed(() => ({
  chart: {
    id: 'channel-chart',
    type: 'bar',
    stacked: true,
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    labels: {
      show: true,
      rotate: 0,
      hideOverlappingLabels: false,
      trim: true,
      style: {
        colors: '#64748b',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  grid: {
    show: false,
  },
  colors: colors,
  labels: chartLabels.value,
  tooltip: {
    theme: 'dark',
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      const current = formatNumber(w.globals.series[0][dataPointIndex]);

      const xLabel = w.globals.labels[dataPointIndex];
      if (dateStore.isComparing) {
        const past = formatNumber(w.globals.series[1][dataPointIndex]);
        const growth = change.value[dataPointIndex];
        return `
          <div style="background-color:#2d3748;padding:25px;border-radius:8px;color:#f7fafc;min-width:12rem;box-shadow:0 4px 8px rgba(0,0,0,0.4);">
      <div style="font-size:14px;margin-bottom:10px;color:#e2e8f0;">
        <strong>${xLabel}</strong>
      </div>
  
      <div style="display:flex;align-items:center;justify-content:space-between;margin-bottom:8px;font-size:13px;">
        <div style="display:flex;align-items:center;">
          <span style="background-color:#fbbf24;width:12px;height:12px;border-radius:50%;margin-right:8px;"></span>
          <span>Current</span>
        </div>
        <div style="color:#fff;"> (${current})</div>
      </div>
  
      <div style="display:flex;align-items:center;justify-content:space-between;margin-bottom:8px;font-size:13px;">
        <div style="display:flex;align-items:center;">
          <span style="background-color:#f59e0b;width:12px;height:12px;border-radius:50%;margin-right:8px;"></span>
          <span>Past</span>
        </div>
        <div style="color:#fff;"> (${past})</div>
      </div>
  
      <hr style="border-top:1px solid #4b5563;margin:10px 0;" />
  
      <div style="font-size:13px;display:flex;justify-content:space-between;">
        <span>Change</span>
        <span style="color: ${growth > 0 ? '#48bb78' : '#f56565'};">
          ${growth > 0 ? '+' : ''}<strong>${growth}%</strong>
        </span>
      </div>
    </div>
        `;
      } else {
        return `
          <div style="background-color:#2d3748;padding:25px;border-radius:8px;color:#f7fafc;min-width:12rem;box-shadow:0 4px 8px rgba(0,0,0,0.4);">
      <div style="font-size:14px;margin-bottom:10px;color:#e2e8f0;">
        <strong>${xLabel}</strong>
      </div>
  
      <div style="display:flex;align-items:center;justify-content:space-between;margin-bottom:8px;font-size:13px;">
        <div style="display:flex;align-items:center;">
          <span style="background-color:#fbbf24;width:12px;height:12px;border-radius:50%;margin-right:8px;"></span>
          <span>Total Users</span>
        </div>
        <div style="color:#fff;"> (${current})</div>
      </div>
  
    </div>
        `;
      }
    },
  },
  noData: {
    text: 'No Activity',
  },
  legend: {
    labels: {
      useSeriesColors: true,
    },
  },
}));
const formattedStartDate = computed(() => {
  const options = { year: 'numeric', month: 'short' };
  return new Date(
    dateStore.isComparing
      ? dateStore?.insightDate?.pastStart
      : dateStore?.insightDate?.currentStart,
  ).toLocaleDateString('en-US', options);
});

const formattedEndDate = computed(() => {
  const options = { year: 'numeric', month: 'short', year: 'numeric' };
  return new Date(dateStore?.insightDate?.currentEnd).toLocaleDateString(
    'en-US',
    options,
  );
});
const fetchData = async () => {
  try {
    loading.value = true;
    if (
      dateStore.insightDate.currentStart &&
      dateStore.insightDate.currentEnd
    ) {
      const params = dateStore.isComparing
        ? {
            start_date: dateStore?.insightDate?.currentStart,
            end_date: dateStore?.insightDate?.currentEnd,
            past_start_date: dateStore?.insightDate?.pastStart,
            past_end_date: dateStore?.insightDate?.pastEnd,
          }
        : {
            start_date: dateStore?.insightDate?.currentStart,
            end_date: dateStore?.insightDate?.currentEnd,
          };
      const response = await Api.get(
        `/google-analytics-insights/highest-converting-traffic-channel-metrics`,
        {
          params: params,
        },
      );

      data.value = JSON.parse(response.data?.items.data);
      if (Array.isArray(data.value) && data.value.length > 0) {
        const labels = data.value.map((items) => items[0]);
        if (dateStore.isComparing) {
          const currentTotalUsers = data.value.map((item) => item[2]);
          const pastTotalUsers = data.value.map((item) => item[1]);
          change.value = data.value.map((item) => item[3]);
          chartLabels.value = labels;

          chartSeries.value = [
            {
              name: 'Past Total Users',
              data: pastTotalUsers,
            },
            {
              name: 'Current Total Users',
              data: currentTotalUsers,
            },
          ];
        } else {
          const currentTotalUsers = data.value.map((item) => item[1]);
          chartLabels.value = labels;
          chartSeries.value = [
            {
              name: 'Current Total Users',
              data: currentTotalUsers,
            },
          ];
        }
      }
    }
  } catch (error) {
    data.value = [];
    change.value = [];
    chartLabels.value = [];
    chartSeries.value = [];
  } finally {
    loading.value = false;
  }
};

watch(
  () => [dateStore.insightDate, dateStore.isComparing],
  async () => {
    await fetchData();
  },
  { immediate: true, deep: true },
);

watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam) {
      currentTeam.value = newTeam;
      fetchData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
