<template>
  <div
    class="rounded-lg p-6 md:p-10 w-full dark:bg-black min-h-screen bg-gray-100 h-full flex flex-col justify-center items-center shadow-lg space-y-6"
  >
    <div
      v-if="loading"
      class="bg flex flex-col justify-center items-center h-full w-full"
    >
      <div
        class="animate-bounce flex items-center justify-center size-10 bg-white rounded-full shadow-sm border border-gray-300 dark:border-gray-600"
      >
        <img
          :src="iconSrc"
          :alt="name"
          class="size-6 object-contain mix-blend-darken"
          v-if="iconSrc"
        />
      </div>

      <div class="text-lg font-semibold font-mono pt-4">
        Connecting your {{ name }} account
      </div>
    </div>
    <div v-else>
      <div
        v-if="connected"
        class="font-medium text-gray-800 dark:text-gray-100 text-center lg:text-lg text-base h-full w-full p-4"
      >
        Account connected successfully
      </div>
      <div v-else>There is some issue. Please try later!</div>
    </div>
  </div>
</template>
<script setup>
import Api from '@/utils/ApiClient';
import { onMounted, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';

const props = defineProps(['data']);
const { sourceType, name } = props.data;
const secretId = ref();
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const connected = ref(false);
const iconSrc = computed(() => {
  const icons = {
    instagram: '/images/instagram.png',
    'tiktok-marketing': '/images/tiktok.png',
  };
  return icons[sourceType] || null;
});
const establishConnection = async () => {
  try {
    loading.value = true;

    const response = await Api.post(
      `/airbyte/${sourceType}`,
      {
        secret_id: secretId.value,
      },
      {
        params: {
          source_type: sourceType,
        },
      },
    );
    if (response.data.message) {
      toast.success(response.data.message || 'Account Connected successfully', {
        onClose: () => {
          router.push('/settings/team/connections');
        },
      });
      connected.value = true;
    }
  } catch (error) {
    toast.error(error.response.data.detail || 'Account Connection Failed!');
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  if (route.query.secret_id) {
    secretId.value = route.query.secret_id;
    establishConnection();
  }
});
</script>
