<template>
  <div
    class="flex flex-col rounded-lg border bg-white dark:bg-gray-950 dark:text-white overflow-hidden p-2 dark:border-gray-700 cursor-grabbing hover:scale-105 transition-transform w-full min-h-40"
  >
    <div class="text-xs font-normal text-left mb-2 flex items-center gap-1">
      <img :src="iconSrc" :alt="srcName" :class="heightClass" v-if="iconSrc" />
      <span class="font-semibold">{{ srcName }}</span>
    </div>

    <div class="flex-1 flex items-center justify-center">
      <span
        v-if="!loading"
        class="text-center text-base lg:text-xl font-medium"
      >
        <div
          v-if="Array.isArray(growth) && growth.length > 0"
          class="flex justify-between mb-2"
        >
          <div
            v-for="(metric, index) in growth"
            :key="index"
            class="flex flex-col"
          >
            <span class="text-sm md:text-base font-medium">{{
              metric?.value
            }}</span>
            <p class="text-xs font-normal">{{ metric?.label }}</p>
          </div>
        </div>
        <div v-else>
          <span class="text-center text-base lg:text-xl font-medium">
            {{ growth }}
          </span>
        </div>
        <p class="text-xs font-normal">{{ name.toLocaleUpperCase() }}</p>
      </span>
      <StyledLoader v-else />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, nextTick, computed } from 'vue';
import Api from '@/utils/ApiClient';
import StyledLoader from './StyledLoader.vue';
import { useTeamStore } from '@/stores/teamStore';
import { useDateStore } from '@/stores/dateStore';

const dateStore = useDateStore();
const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);

const props = defineProps({
  apiEndpoint: {
    type: String,
    required: true,
  },
  metricName: {
    type: String,
    required: true,
  },
  formatData: {
    type: Function,
    default: (data) => data,
  },
  srcName: {
    type: String,
    default: 'Google Ads',
  },
});

const name = props.metricName;
const growth = ref('');
const loading = ref(false);
const iconSrc = computed(() => {
  const icons = {
    'Google Ads': '/images/google-ads.png',
    'Facebook Ads': '/images/facebook_ads.png',
    'Google Analytics': '/images/google_analytics_4.png',
    'Google Search Console': '/images/google-search-console.png',
    Instagram: '/images/instagram.png',
    TikTok: '/images/tiktok.png',
    'YouTube Analytics': '/images/youtube.png',
    'LinkedIn Ads': '/images/linkedin.png',
    'LinkedIn Pages': '/images/linkedin.png',
    'Amazon Ads': '/images/amazon.png',
    Blinkit: '/images/blinkit.png',
  };
  return icons[props.srcName] || null;
});
const heightClass = computed(() => {
  const heightClasses = {
    'Google Ads': 'h-4',
    'Facebook Ads': 'h-4',
    'Google Analytics': 'h-4',
    'Google Search Console': 'h-4',
    Instagram: 'h-4',
    TikTok: 'h-5 ',
    'YouTube Analytics': 'h-3',
    'LinkedIn Ads': 'h-4',
    'LinkedIn Pages': 'h-4 ',
    'Amazon Ads': 'h-3',
    Blinkit: 'h-4',
  };
  return heightClasses[props.srcName] || 'h-4';
});
const fetchData = async () => {
  loading.value = true;
  growth.value = '';
  try {
    const response = await Api.get(props.apiEndpoint, {
      params: {
        start_date: dateStore?.dashboardDate?.currentStart,
        end_date: dateStore?.dashboardDate?.currentEnd,
      },
    });
    const data = response?.data?.items;

    if (data?.data && data?.labels) {
      growth.value = data.data.map((value, index) => ({
        value: value + '%',
        label: data.labels[index] || `Metric ${index + 1}`,
      }));
    } else {
      growth.value = props.formatData(data);
    }
  } catch (error) {
    growth.value = 'No Activity';
  } finally {
    loading.value = false;
  }
};
watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
    if (newTeam.id) {
      fetchData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
watch(
  () => dateStore.dashboardDate,
  async () => {
    await nextTick();
    await fetchData();
  },
  { immediate: true, deep: true },
);
</script>
