<template>
  <div
    :class="[
      'p-2 rounded-lg shadow-md transition duration-300 pt-3 h-full dark:bg-black-600 grid grid-rows-2 ',
      completed ? 'bg-green-200' : 'bg-gray-200',
    ]"
  >
    <!-- Display appropriate icon based on the 'name' prop -->
    <div
      class="flex justify-center items-center m-2 gap-2"
      v-if="sourceType == 'blinkit'"
    >
      <img src="/images/blinkit.png" class="h-8 w-fit" :alt="name" />

      <div class="font-inter font-medium text-gray-800">{{ name }}</div>
    </div>

    <div class="h-full">
      <div class="flex">
        <div class="flex-1 flex justify-center items-center mx-auto">
          <button
            @click="createConnection"
            :class="[
              ' font-semibold py-3 px-4 rounded-lg transition duration-300 text-wrap w-fit text-xs overflow-hidden lg:text-sm',
              completed
                ? 'text-gray-800 bg-gradient-to-r from-emerald-300 to-emerald-400 hover:from-emerald-400 hover:to-emerald-500'
                : 'bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 text-white',
            ]"
          >
            {{ completed ? 'Upload Next File' : 'Upload First File' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useTeamStore } from '@/stores/teamStore';
import Api from '@/utils/ApiClient';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const props = defineProps(['data']);
const { sourceType, name } = props.data;
const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
const completed = ref(false);

const createConnection = async () => {
  router.push({ path: '/connector', query: { name } });
};
const fetchConnectionData = async () => {
  try {
    if (currentTeam.value?.id) {
      const { data } = await Api.get(`/${name?.toLowerCase()}/connection`);
      completed.value = data;
    }
  } catch (error) {
    console.error('Error fetching team custom connectors:', error);
    completed.value = false;
  }
};

watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
    if (newTeam?.id) {
      fetchConnectionData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>

<style scoped></style>
