<template>
  <InsightCard
    title="Top Cities based on engaged sessions"
    column1="City"
    column2="Share"
    column3="Engaged Sessions"
    apiUrl="/google-analytics-insights/city-wise-engagement-metrics"
    :formatter="formatNumber"
    :displayIndex="index"
    :change="3"
    srcName="gstats"
  />
</template>

<script setup>
import { formatNumber } from '@/utils/helperFunctions';
import InsightCard from '@/components/InsightCard.vue';
const index = [0, 1];
</script>
