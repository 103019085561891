<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-2 px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 min-h-full flex-grow"
  >
    <div class="text-base md:text-lg">Engagement Distribution</div>
    <div v-if="loading" class="flex flex-1 justify-center">
      <StyledLoader />
    </div>
    <div v-else class="flex-1 grid">
      <div
        class="flex justify-center items-center flex-col lg:flex-row w-full"
        v-if="data"
      >
        <div class="lg:w-2/3">
          <apexchart
            type="donut"
            :options="chartOptions"
            :series="chartSeries"
          ></apexchart>
        </div>
        <div class="text-sm flex lg:flex-col flex-wrap lg:space-y-2">
          <span
            v-for="(item, index) in labelData"
            :key="index"
            :style="{ color: colors[index] }"
          >
            ● {{ item }}
          </span>
        </div>
      </div>
      <div v-else class="flex justify-center items-center text-base md:text-lg">
        No activity.
      </div>
    </div>
    <SourceBadge
      src="/images/youtube.png"
      alt="YouTube Analytics"
      label="YouTube Analytics"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import Api from '@/utils/ApiClient';
import { useTeamStore } from '@/stores/teamStore';
import { useDateStore } from '@/stores/dateStore';
import StyledLoader from '@/components/StyledLoader.vue';
import SourceBadge from '@/components/SourceBadge.vue';

const data = ref();
const labelData = ref();
const chartLabels = ref([]);
const dateStore = useDateStore();
const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
const loading = ref(false);
const colors = ['#50C878', '#FF0000', '#f59e0b', '#60a5fa', '#D2042D'];
const chartOptions = computed(() => ({
  chart: {
    id: 'engagement-distribution-chart',
    type: 'donut',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  colors: colors,
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  tooltip: {
    theme: 'dark',
  },
  noData: {
    text: 'No Activity',
  },
  labels: chartLabels.value,
}));

const chartSeries = ref([0, 0]);

const fetchData = async () => {
  try {
    loading.value = true;
    const response = await Api.get(
      `/youtube-analytics/engagement-distribution`,
      {
        params: {
          start_date: dateStore?.dashboardDate?.currentStart,
          end_date: dateStore?.dashboardDate?.currentEnd,
        },
      },
    );
    if (response.data) {
      data.value = response.data.items;
      if (data.value?.columns && data.value?.data) {
        const labels = [...data.value.columns];
        const series = [...data.value.data];
        labelData.value = labels.map(
          (label, index) => label.toUpperCase() + '-' + series[index],
        );
        chartLabels.value = labels;
        chartSeries.value = series;
      }
    }
  } catch (error) {
    data.value = [];
    labelData.value = [];
    chartLabels.value = [];
    chartSeries.value = [];
  } finally {
    loading.value = false;
  }
};
watch(
  [dateStore.dashboardDate],
  async () => {
    await fetchData();
  },
  { immediate: true, deep: true },
);

watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam) {
      currentTeam.value = newTeam;
      fetchData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
