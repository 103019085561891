<script setup>
import { computed, ref, watch } from 'vue';
import DatePicker from '@/components/DatePicker.vue';
import { toast } from 'vue3-toastify';
import { Sparkles } from 'lucide-vue-next';
import { useDateStore } from '@/stores/dateStore';
import ChartLayout from './ChartLayout.vue';

const props = defineProps({
  team: {
    required: true,
  },
});

const isModalOpen = ref(false);
const isDataVisible = ref(false);
const isAnimating = ref(false);
const stopFetching = ref(false);
const apiData = ref('');
const dateStore = useDateStore();
const startDate = ref(dateStore.dashboardDate.currentStart);
const endDate = ref(dateStore.dashboardDate.currentEnd);

watch(
  dateStore.dashboardDate,
  () => {
    startDate.value = dateStore.dashboardDate.currentStart;
    endDate.value = dateStore.dashboardDate.currentEnd;
  },
  {
    immediate: true,
    deep: true,
  },
);
let abortController;

const toggleData = () => {
  if (isDataVisible.value) {
    isDataVisible.value = false;
    stopFetching.value = true;
    if (abortController) {
      abortController.abort();
    }
    apiData.value = '';
  } else {
    isDataVisible.value = true;
    stopFetching.value = false;
    fetchData();
  }
};

const backendUrl = import.meta.env.VITE_BACKEND_URL;
const accessToken = localStorage.getItem('access_token');

const fetchData = async () => {
  const eventUrl = `${backendUrl}/api/ai/metrics-summarization?start_date=${startDate.value}&end_date=${endDate.value}`;

  abortController = new AbortController();
  const { signal } = abortController;

  try {
    isAnimating.value = true;
    const response = await fetch(eventUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'text/stream',
        team_id: props.team.id,
      },
      signal,
    });

    if (!response.ok) {
      toast.error('Failed to get AI summary. Please try later!');
      return;
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let result = '';

    const processStream = async () => {
      const { done, value } = await reader.read();
      if (done || stopFetching.value) {
        apiData.value = result;
        isAnimating.value = false;
        return;
      }

      result += decoder.decode(value, { stream: true });
      apiData.value = result;

      if (apiData.value.length > 0 && isAnimating.value) {
        isAnimating.value = false;
      }

      processStream();
    };

    processStream();
  } catch (error) {
    console.error('Fetch error:', error);
    toast.error('Failed to get AI summary. Please try later!');
    isAnimating.value = false;
    isDataVisible.value = false;
    apiData.value = '';
  }
};
const dayDifference = computed(() => {
  return Math.ceil(
    (new Date(endDate.value) - new Date(startDate.value)) /
      (1000 * 60 * 60 * 24),
  );
});
const formattedStartDate = computed(() => {
  const options = { day: 'numeric', month: 'short' };
  return new Date(startDate.value).toLocaleDateString('en-US', options);
});

const formattedEndDate = computed(() => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return new Date(endDate.value).toLocaleDateString('en-US', options);
});

const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};
</script>

<template>
  <div class="flex justify-between px-3 pt-4 items-center flex-col sm:flex-row">
    <div class="text-xl sm:text-2xl font-semibold dark:text-white">
      {{ props.team?.name }}
    </div>
    <div class="flex flex-col md:flex-row gap-3 w-full sm:w-auto">
      <div
        @click="toggleData"
        class="flex gap-2 border rounded-md p-2 cursor-pointer text-white bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-xs sm:text-sm justify-center items-center flex-wrap"
      >
        <Sparkles :size="16" class="sm:self-center" />Explain this to me
      </div>
      <div
        @click="openModal"
        class="flex items-center p-2 rounded-lg bg-gray-200 dark:bg-gray-800 shadow-md space-x-4 cursor-pointer justify-around"
      >
        <span
          class="text-xs font-semibold text-gray-600 dark:text-gray-300 bg-gray-300 dark:bg-gray-700 px-2 py-1 rounded"
        >
          {{ dayDifference }} days
        </span>
        <div class="text-sm font-semibold dark:text-white">
          {{ formattedStartDate }} - {{ formattedEndDate }}
        </div>

        <svg
          :class="{ 'rotate-180': isModalOpen }"
          class="transition-transform"
          width="16"
          height="16"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 9l-7 7-7-7"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
  <!-- DatePicker Component -->
  <DatePicker
    :compareRequire="false"
    :isModalOpen="isModalOpen"
    @close="closeModal"
  />
  <!-- AI Component -->
  <div
    v-if="isDataVisible"
    class="p-4 rounded-md scroll-custom bg-white dark:bg-gray-950 m-3"
  >
    <h3
      class="text-base md:text-lg font-bold mb-3 dark:text-gray-100 text-gray-900"
    >
      Insight:
    </h3>
    <div
      class="overflow-auto max-h-80 p-3 bg-gradient-to-r from-gray-100 to-gray-200 dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 rounded"
    >
      <div v-if="isAnimating" class="ai-loader">
        <div
          class="animate-bounce text-transparent font-semibold bg-clip-text bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 text-5xl"
        >
          ✨
        </div>
      </div>
      <pre
        v-else
        class="text-base text-gray-800 dark:text-gray-200 whitespace-pre-wrap font-roboto"
        >{{ apiData }}
    </pre
      >
    </div>
  </div>
  <ChartLayout />
</template>
<style scoped>
input[type='date'] {
  padding: 0.5rem;
  height: 2rem;
  width: 8rem;
  border-radius: 0.375rem;
}

.dark input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(1);
  width: 1rem;
  height: 1rem;
}

.dark input[type='date']::-moz-calendar-picker-indicator {
  filter: invert(1);
  width: 1rem;
  height: 1rem;
}

.dark input[type='date']::-ms-calendar-picker-indicator {
  filter: invert(1);
  width: 1rem;
  height: 1rem;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: gray;
}
</style>
