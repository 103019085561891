import { calculateDate } from '@/utils/helperFunctions';
import { defineStore } from 'pinia';

const locallySavedInsightDate = JSON.parse(
  localStorage.getItem('insight_date'),
);
const locallySavedDashBoardDate = JSON.parse(
  localStorage.getItem('dashboard_date'),
);

const initialInsightDate = {
  currentStart: calculateDate(-31),
  currentEnd: calculateDate(-1),
  pastStart: calculateDate(-62),
  pastEnd: calculateDate(-32),
};
const initialDashboardDate = {
  currentStart: calculateDate(-8),
  currentEnd: calculateDate(-1),
};
export const useDateStore = defineStore('date', {
  state: () => ({
    insightDate: locallySavedInsightDate || initialInsightDate,
    dashboardDate: locallySavedDashBoardDate || initialDashboardDate,
    isComparing: true,
  }),
  actions: {
    setInsightDate(name, value) {
      this.insightDate[name] = value;
      this.saveInsightDate();
    },
    setDashboardDate(name, value) {
      this.dashboardDate[name] = value;
      this.saveDashBoardDate();
    },
    setIsComparing() {
      this.isComparing = !this.isComparing;
    },
    saveInsightDate() {
      localStorage.setItem('insight_date', JSON.stringify(this.insightDate));
    },
    saveDashBoardDate() {
      localStorage.setItem(
        'dashboard_date',
        JSON.stringify(this.dashboardDate),
      );
    },
    clear() {
      this.insightDate = initialInsightDate;
      this.dashboardDate = initialDashboardDate;
    },
  },
});
