<template>
  <MetricCard
    apiEndpoint="/google-ads/click-through-rate"
    metricName="CTR"
    :formatData="formatCTR"
    srcName="Google Ads"
  />
</template>

<script setup>
import MetricCard from '@/components/MetricCard.vue';

const formatCTR = (data) => data.data + '%';
</script>
