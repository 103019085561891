<template>
  <MetricCard
    apiEndpoint="/instagram/reach"
    metricName="REACH"
    :formatData="formatData"
    srcName="Instagram"
  />
</template>

<script setup>
import MetricCard from '@/components/MetricCard.vue';
import { formatNumber } from '@/utils/helperFunctions';
const formatData = (data) => formatNumber(data.data);
</script>
