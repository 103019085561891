<template>
  <div
    class="p-6 rounded-lg bg-white dark:bg-gray-950 dark:text-white shadow-md"
  >
    <!-- Header Section -->
    <div class="flex justify-between items-center flex-wrap mb-6 gap-2">
      <div class="text-lg font-semibold flex-1">Top Engaging Media</div>

      <div class="flex items-center text-sm gap-2">
        <Calendar :size="16" />
        <span>{{ formattedStartDate }} - {{ formattedEndDate }}</span>
      </div>
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Search by caption"
        class="py-2 px-4 rounded-lg border border-gray-300 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-700 text-black"
      />
      <Grid3x3 v-if="isCardLayout" @click="toggleCardLayout" />
      <LayoutGrid v-else @click="toggleCardLayout" />
    </div>

    <!-- Loader or Content -->
    <div class="bg-white dark:bg-gray-900">
      <div v-if="loading" class="flex justify-center items-center h-64">
        <StyledLoader />
      </div>
      <div v-else>
        <div
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
          v-if="isCardLayout"
        >
          <MediaCard
            v-for="(row, index) in sortedData"
            :key="index"
            :engagement="row.engagement"
            :reach="row.reach"
            :likes="row.likes"
            :comments="row.comments"
            :shares="row.shares"
            :saved="row.saved"
            :caption="row.caption"
            :permalink="row.permalink"
            :mediaUrl="row.media_url"
            :mediaType="row.media_type"
            :type="row.type"
          />
        </div>
        <MediaTable
          v-else
          :filteredData="filteredData"
          :sortedData="sortedData"
          :sortBy="sortBy"
          :sortOrder="sortOrder"
          :sortData="sortData"
        />
        <div
          v-if="!filteredData.length"
          class="text-center text-gray-500 dark:text-gray-300 mt-4"
        >
          No data available.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import StyledLoader from '@/components/StyledLoader.vue';
import Api from '@/utils/ApiClient';
import { useTeamStore } from '@/stores/teamStore';
import { useDateStore } from '@/stores/dateStore';
import { Calendar } from 'lucide-vue-next';
import MediaTable from '../MediaTable.vue';
import MediaCard from '../MediaCard.vue';
import { Grid3x3 } from 'lucide-vue-next';
import { LayoutGrid } from 'lucide-vue-next';

const loading = ref(true);
const parsedData = ref([]);
const teamStore = useTeamStore();
const dateStore = useDateStore();
const searchQuery = ref('');
const sortBy = ref('');
const sortOrder = ref('asc');
const isCardLayout = ref(false);
const toggleCardLayout = () => {
  isCardLayout.value = !isCardLayout.value;
};
const formattedStartDate = computed(() => {
  const options = { year: 'numeric', month: 'short' };
  return new Date(dateStore?.insightDate?.currentStart).toLocaleDateString(
    'en-US',
    options,
  );
});

const formattedEndDate = computed(() => {
  const options = { year: 'numeric', month: 'short', year: 'numeric' };
  return new Date(dateStore?.insightDate?.currentEnd).toLocaleDateString(
    'en-US',
    options,
  );
});

const filteredData = computed(() => {
  return parsedData.value.filter((row) =>
    row.caption.toLowerCase().includes(searchQuery.value.toLowerCase()),
  );
});

const sortedData = computed(() => {
  const data = [...filteredData.value];
  if (sortBy.value) {
    data.sort((a, b) => {
      const compare =
        sortOrder.value === 'asc'
          ? a[sortBy.value] - b[sortBy.value]
          : b[sortBy.value] - a[sortBy.value];
      return compare;
    });
  }
  return data;
});

const sortData = (key) => {
  if (sortBy.value === key) {
    sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortBy.value = key;
    sortOrder.value = 'asc';
  }
};

const fetchData = async () => {
  try {
    loading.value = true;
    const response = await Api.get(`/instagram-insights/interactions`, {
      params: {
        start_date: dateStore.insightDate.currentStart,
        end_date: dateStore.insightDate.currentEnd,
      },
    });

    const rawData = JSON.parse(response.data.items.data);
    parsedData.value = rawData.map((item) => ({
      engagement: item[0],
      likes: item[1],
      comments: item[2],
      shares: item[3],
      saved: item[4],
      reach: item[5],
      permalink: item[6],
      media_url: item[9],
      caption: item[10],
      media_type: item[7],
      type: determineType(item[7], item[8]),
      icon: typeIcon(item[7], item[8]),
    }));
  } catch (error) {
    console.error('Failed to fetch data:', error);
    parsedData.value = [];
  } finally {
    loading.value = false;
  }
};

const determineType = (mediaType, productType) => {
  if (productType === 'REELS') return 'Reel';
  if (productType === 'FEED' && mediaType === 'CAROUSEL_ALBUM')
    return 'Carousel Album';
  if (['IMAGE', 'VIDEO'].includes(mediaType) && productType === 'FEED')
    return 'Post';
  return '';
};
const typeIcon = (mediaType, productType) => {
  if (productType === 'REELS') return '/images/reel_icon.png';
  if (productType === 'FEED' && mediaType === 'CAROUSEL_ALBUM')
    return '/images/carousel_icon.png';
  if (['IMAGE', 'VIDEO'].includes(mediaType) && productType === 'FEED')
    return '/images/post-grid_icon.png';
  return '';
};

watch(
  () => dateStore.insightDate,
  async () => {
    await fetchData();
  },
  { immediate: true, deep: true },
);

watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam) {
      fetchData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
