<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-2 px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 h-full"
  >
    <div class="text-base md:text-lg">Impressions VS Clicks</div>

    <div v-if="loading" class="flex flex-1 justify-center">
      <StyledLoader />
    </div>
    <div v-else class="flex-1 grid">
      <div
        v-if="error"
        class="flex justify-center items-center text-base md:text-lg"
      >
        No Activity.
      </div>
      <div
        class="my-2 mx-auto flex-grow flex justify-center items-center"
        v-else
      >
        <apexchart
          type="line"
          :options="chartOptions"
          :series="chartSeries"
          class="flex-1"
        ></apexchart>
      </div>
    </div>
    <SourceBadge
      src="/images/google-ads.png"
      alt="Google Ads"
      label="Google Ads"
    />
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { formatNumber } from '@/utils/helperFunctions';
import { useTeamStore } from '@/stores/teamStore';
import StyledLoader from '@/components/StyledLoader.vue';
import SourceBadge from '@/components/SourceBadge.vue';
import { useDateStore } from '@/stores/dateStore';
import Api from '@/utils/ApiClient';

const teamStore = useTeamStore();
const dateStore = useDateStore();
const loading = ref(false);
const data = ref([]);
const error = ref(false);
const categories = ref([]);
const chartSeries = ref([
  {
    name: 'Impressions',
    data: [],
  },
  {
    name: 'Clicks',
    data: [],
  },
]);

const fetchData = async () => {
  try {
    loading.value = true;
    const response = await Api.get(`/google-ads/impression-and-clicks`, {
      params: {
        start_date: dateStore.dashboardDate.currentStart,
        end_date: dateStore.dashboardDate.currentEnd,
      },
    });
    if (response.data?.items) {
      error.value = false;
      data.value = JSON.parse(response.data?.items.data);
      generateChartData();
    } else {
      error.value = true;
      data.value = [];
      categories.value = [];
      chartSeries.value = [
        {
          name: 'Impressions',
          data: [],
        },
        {
          name: 'Clicks',
          data: [],
        },
      ];
    }
  } catch (error) {
    error.value = true;
    data.value = [];
    categories.value = [];
    chartSeries.value = [
      {
        name: 'Impressions',
        data: [],
      },
      {
        name: 'Clicks',
        data: [],
      },
    ];
  } finally {
    loading.value = false;
  }
};

const generateChartData = () => {
  const rawData = data.value.map(([impressions, clicks, timestamp]) => [
    impressions,
    clicks,
    new Date(timestamp),
  ]);

  rawData.sort((a, b) => a[2] - b[2]);

  const startDate = rawData[0][2];
  const endDate = rawData[rawData.length - 1][2];

  const dateDiffInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
  let groupBy = 'day';
  if (dateDiffInDays >= 90) groupBy = 'month';
  else if (dateDiffInDays >= 30) groupBy = 'week';

  const groupedData = {};

  rawData.forEach((item) => {
    const timestamp = item[2];
    let groupKey;
    let groupDate;

    if (groupBy === 'month') {
      groupDate = new Date(timestamp.getFullYear(), timestamp.getMonth(), 1);
      groupKey = `${groupDate.getFullYear()}-${groupDate.getMonth() + 1}`;
    } else if (groupBy === 'week') {
      const weekStart = new Date(timestamp);
      weekStart.setDate(weekStart.getDate() - weekStart.getDay());

      groupDate = new Date(weekStart);
      groupKey = weekStart.toISOString().split('T')[0];
    } else {
      groupDate = timestamp;
      groupKey = groupDate.toISOString().split('T')[0];
    }

    if (!groupedData[groupKey]) {
      groupedData[groupKey] = {
        rawDate: groupDate,
        values: [0, 0],
      };
    }

    groupedData[groupKey].values[0] += item[0] || 0;
    groupedData[groupKey].values[1] += item[1] || 0;
  });

  chartSeries.value = [
    {
      name: 'Impressions',
      data: Object.values(groupedData).map((item) => item.values[0]),
    },
    {
      name: 'Clicks',
      data: Object.values(groupedData).map((item) => item.values[1]),
    },
  ];
  data.value = groupedData;
  categories.value = Object.values(groupedData).map((item) => {
    const timestamp = new Date(item.rawDate);

    if (groupBy === 'month') {
      return timestamp.toLocaleDateString('en-GB', {
        month: 'short',
        year: 'numeric',
      });
    }

    if (groupBy === 'week') {
      const weekStart = new Date(item.rawDate);
      const weekEnd = new Date(item.rawDate);

      weekStart.setDate(weekStart.getDate() - weekStart.getDay());
      weekEnd.setDate(weekStart.getDate() + 6);

      return `${weekStart.getDate()} ${weekStart.toLocaleDateString('en-GB', {
        month: 'short',
      })} - ${weekEnd.getDate()} ${weekEnd.toLocaleDateString('en-GB', {
        month: 'short',
        year: '2-digit',
      })}`;
    }

    return timestamp.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
    });
  });
};

const chartOptions = computed(() => ({
  chart: {
    id: 'impressions-and-click-chart',
    type: 'line',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  colors: ['#3b82f6', '#a855f7'],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  xaxis: {
    categories: categories.value,
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  grid: {
    show: false,
  },
  tooltip: {
    custom: function ({ series, seriesIndex, dataPointIndex }) {
      const actualDataPoint = Object.values(data.value)[dataPointIndex].values;
      const date = categories.value[dataPointIndex];
      if (actualDataPoint) {
        return `<div class="tooltip   text-white">
                  <p class='text-center bg-black py-2'>${date}</p>
                  <p class='py-1 px-3'>Impressions: ${formatNumber(actualDataPoint[0])}</p>
                  <p class='py-1 px-3'>Clicks: ${formatNumber(actualDataPoint[1])}</p>
                  
                </div>`;
      }
      return '<div>No activity</div>';
    },
    theme: 'dark',
  },
  noData: {
    text: 'No Activity',
  },
  legend: {
    position: 'bottom',
    labels: {
      useSeriesColors: true,
    },
  },
}));
watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam.id) {
      fetchData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);

watch(
  () => dateStore.dashboardDate,
  () => {
    fetchData();
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
