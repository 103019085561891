<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-4 px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 min-h-full flex-grow max-h-[420px] md:max-h-[500px]"
  >
    <div class="flex justify-between flex-wrap">
      <div class="text-sm md:text-base font-semibold mb-2 dark:text-white">
        Follower Distribution (Location)
      </div>
      <div
        class="flex text-xs md:text-sm items-center text-gray-500 dark:text-gray-300 px-4 gap-1 mb-2"
      >
        <Calendar :size="16" />
        All Time
      </div>
    </div>
    <div class="flex gap-2 mb-2">
      <button
        :class="[
          'border px-3 py-1 rounded-md text-sm font-medium transition-all',
          selectedView === 'city'
            ? 'border-blue-500 text-blue-500'
            : 'border-gray-300 text-gray-600 dark:border-gray-700 dark:text-gray-400',
        ]"
        @click="switchView('city')"
      >
        City
      </button>
      <button
        :class="[
          'border px-3 py-1 rounded-md text-sm font-medium transition-all',
          selectedView === 'country'
            ? 'border-blue-500 text-blue-500'
            : 'border-gray-300 text-gray-600 dark:border-gray-700 dark:text-gray-400',
        ]"
        @click="switchView('country')"
      >
        Country
      </button>
    </div>
    <!-- Loader or Chart -->
    <div v-if="loading" class="flex flex-1 justify-center">
      <StyledLoader />
    </div>
    <div v-else class="flex-1 grid">
      <div
        v-if="chartSeries.length > 0"
        class="flex justify-center items-center flex-col lg:flex-row"
      >
        <apexchart
          type="donut"
          :options="chartOptions"
          :series="chartSeries"
        ></apexchart>
        <div
          class="text-xs md:text-base lg:text-lg flex lg:flex-col gap-2 md:gap-0 flex-wrap justify-center"
        >
          <span
            v-for="(item, index) in labelData"
            :key="index"
            :style="{ color: colors[index] }"
          >
            ● {{ item }}
          </span>
        </div>
      </div>
      <div v-else class="flex justify-center items-center text-base md:text-lg">
        No activity.
      </div>
    </div>

    <!-- Source badge -->
    <SourceBadge
      src="/images/instagram.png"
      alt="Instagram"
      label="Instagram"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import Api from '@/utils/ApiClient';
import { useTeamStore } from '@/stores/teamStore';
import { formatNumber } from '@/utils/helperFunctions';
import { Calendar } from 'lucide-vue-next';
import StyledLoader from '@/components/StyledLoader.vue';
import SourceBadge from '@/components/SourceBadge.vue';

const teamStore = useTeamStore();

const selectedView = ref('city');
const currentTeam = ref(teamStore.current_team);
const labelData = ref([]);
const loading = ref(false);

const chartLabels = ref([]);
const chartSeries = ref([]);
const colors = ['#60a5fa', '#f59e0b', '#FF69B4', '#FF7F50', '#fbbf24'];
const chartOptions = computed(() => ({
  chart: {
    id: `${selectedView.value}-wise-chart`,
    type: 'donut',
    toolbar: { show: false },
    zoom: { enabled: false },
  },
  colors: colors,
  labels: chartLabels.value,
  legend: { show: false },
  tooltip: { theme: 'dark' },
  noData: { text: 'No Activity' },
  dataLabels: { enabled: false },
}));

const fetchData = async () => {
  try {
    loading.value = true;
    const endpoint =
      selectedView.value === 'city'
        ? '/instagram-insights/follower-demographics-location'
        : '/instagram-insights/follower-demographics-country';
    const response = await Api.get(endpoint);
    const items = response.data?.items;
    if (items && items.data && items.columns) {
      chartLabels.value = items.columns;
      chartSeries.value = items.data;
      labelData.value = items.columns.map(
        (label, index) =>
          `${label.split(',')[0].toUpperCase()} - ${formatNumber(items.data[index])}`,
      );
    } else {
      chartLabels.value = [];
      chartSeries.value = [];
      labelData.value = [];
    }
  } catch (error) {
    console.error('Error fetching data', error);
    chartLabels.value = [];
    chartSeries.value = [];
    labelData.value = [];
  } finally {
    loading.value = false;
  }
};

const switchView = (view) => {
  if (view !== selectedView.value) {
    selectedView.value = view;
    fetchData();
  }
};

watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam) {
      currentTeam.value = newTeam;
      fetchData();
    }
  },
  { immediate: true, deep: true },
);
</script>
