<template>
  <MetricCard
    apiEndpoint="/google-ads/clicks"
    metricName="CLICKS"
    :formatData="formatClicks"
    srcName="Google Ads"
  />
</template>

<script setup>
import { formatNumber } from '@/utils/helperFunctions';
import MetricCard from '@/components/MetricCard.vue';

const formatClicks = (data) => formatNumber(data.data);
</script>
