<template>
  <div class="grid grid-rows-10 dark:text-white">
    <div class="row-span-1">
      <div
        class="flex text-lg gap-2 bg-gray-50 dark:bg-black items-center font-semibold h-full w-full py-2 px-4 md:px-6 md:py-4 border-y border-gray-300 dark:border-gray-600"
      >
        <button class="rotate-90" @click.prevent="goBackToTeam">
          <ChevronsDown />
        </button>

        <div
          class="flex items-center justify-center size-12 bg-white rounded-full shadow-lg border border-gray-300 dark:border-gray-600"
        >
          <img
            src="/images/youtube.png"
            alt="YouTube"
            class="size-8 object-contain mix-blend-darken"
          />
        </div>
        <div>YouTube Analytics</div>
      </div>
    </div>
    <div class="row-span-9 grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <div
          v-if="loading"
          class="flex flex-col items-center justify-center gap-4 h-full"
        >
          <Loader />
          <p
            class="text-base text-gray-700 dark:text-gray-300 text-center font-medium"
          >
            This process may take a few moments.
          </p>
        </div>

        <div v-else class="w-full flex justify-center items-center h-full py-4">
          <div
            v-if="connected"
            class="font-medium text-gray-800 dark:text-gray-100 text-lg"
          >
            <div class="px-4 py-2 text-base md:text-lg">
              Youtube Report Created
              <p class="text-sm md:text-base">
                Complete the next step to successfully connect your account
              </p>
            </div>
            <div class="grid grid-cols-2 gap-2 px-4">
              <button
                @click="goBackToTeam"
                class="inline-flex items-center justify-center px-4 py-2 bg-gray-200 border border-transparent rounded-md font-semibold text-xs text-black uppercase tracking-widest hover:bg-gray-300 focus:bg-gray-300 active:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 transition ease-in-out duration-150"
              >
                Cancel setup
              </button>
              <button
                @click="goNext"
                class="inline-flex items-center justify-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 focus:bg-gray-700 active:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 transition ease-in-out duration-150"
              >
                Continue
              </button>
            </div>
          </div>
          <div
            v-else
            class="font-medium text-gray-800 dark:text-gray-100 text-lg"
          >
            <p>There is an issue with the connector. Please try again later!</p>
          </div>
        </div>
      </div>

      <div
        class="p-4 border-x border-gray-300 dark:border-gray-600 text-sm text-gray-600 dark:text-gray-300 text-left space-y-6"
      >
        <p class="font-semibold text-lg text-gray-800 dark:text-gray-100 mb-4">
          To get the YouTube connector up and running, please follow these
          steps:
        </p>

        <div
          class="p-6 border-l-4 border-green-500 bg-gray-50 dark:bg-gray-700 rounded-md"
        >
          <div class="flex items-center justify-between space-x-4">
            <p class="font-semibold text-base text-gray-800 dark:text-gray-100">
              1. Grant Access for YouTube Report
            </p>
            <span v-if="code" class="text-2xl text-green-600">&#10003;</span>
          </div>
          <p class="text-sm text-gray-600 dark:text-gray-300 mt-2">
            First, you'll need to grant access for creating a YouTube report.
          </p>
        </div>

        <div
          class="p-6 border-l-4 border-yellow-500 bg-gray-50 dark:bg-gray-700 rounded-md"
        >
          <p class="font-semibold text-base text-gray-800 dark:text-gray-100">
            2. Grant Access for ETL System Connection
          </p>
          <p class="text-sm text-gray-600 dark:text-gray-300 mt-2">
            Then, grant access to connect your account with our ETL system.
          </p>
        </div>

        <div
          class="p-6 border-l-4 border-blue-500 bg-gray-50 dark:bg-gray-700 rounded-md"
        >
          <p class="font-semibold text-base text-gray-800 dark:text-gray-100">
            3. Finalize and Redirect
          </p>
          <p class="text-sm text-gray-600 dark:text-gray-300 mt-2">
            Once completed, you'll be redirected to the connections page
            automatically.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Loader from '@/components/Loader.vue';
import Api from '@/utils/ApiClient';
import { ChevronsDown } from 'lucide-vue-next';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { toast } from 'vue3-toastify';

const code = ref();
const route = useRoute();
const loading = ref(false);
const connected = ref(false);
const router = useRouter();
const nextUrl = ref('');
const goBackToTeam = () => {
  router.push('/settings/team/connections');
};
const goNext = () => {
  window.location.href = nextUrl.value;
};
const establishConnection = async () => {
  try {
    loading.value = true;

    const response = await Api.post(`/airbyte/youtube-connection`, {
      auth_code: code.value,
    });

    if (response.status == 200) {
      connected.value = true;
      nextUrl.value = response.data;
    }
  } catch (error) {
    toast.error(error.response.data.detail || 'Something Went Wrong!');
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  if (route.query.code) {
    code.value = route.query.code;
    establishConnection();
  }
});
</script>
