<script setup>
import BrandVsNonBrandClicks from '@/components/charts/google-search-console/BrandVsNonBrandClicks.vue';
import BrandVsNonBrandImpressions from '@/components/charts/google-search-console/BrandVsNonBrandImpressions.vue';
import ChannelWiseUserTrend from '@/components/charts/google-analytics/ChannelWiseUserTrend.vue';
import DeviceTrendChart from '@/components/charts/google-analytics/DeviceTrendChart.vue';
import CampaignWithLowCostPerConversionTable from '@/components/insights/CampaignWithLowCostPerConversionTable.vue';
import CityWiseEngagedSessions from '@/components/insights/CityWiseEngagedSessions.vue';
import CityWiseUserTrend from '@/components/insights/CityWiseUserTrend.vue';
import LandingPageTable from '@/components/insights/LandingPageTable.vue';
import KeywordLevelSpend from '@/components/insights/KeywordLevelSpend.vue';
import KeywordLevelConversion from '@/components/insights/KeywordLevelConversion.vue';
import CampaignLevelSpend from '@/components/insights/CampaignLevelSpend.vue';
import CampaignLevelConversion from '@/components/insights/CampaignLevelConversion.vue';
import AvgPosition from '@/components/insights/AvgPosition.vue';
import { useTeamStore } from '@/stores/teamStore';
import { computed } from 'vue';
import DeviceDistribution from '@/components/charts/youtube/DeviceDistribution.vue';
import GenderDistribution from '@/components/charts/youtube/GenderDistribution.vue';
import AgeGroupDistributionInsta from '@/components/charts/instagram/AgeGroupDistributionInsta.vue';
import FollowerDistribution from '@/components/charts/instagram/FollowerDistribution.vue';
import InteractionsInstagram from '@/components/insights/InteractionsInstagram.vue';
import AgeGroupDistribution from '@/components/charts/youtube/AgeGroupDistribution.vue';
import CountryDistribution from '@/components/charts/youtube/CountryDistribution.vue';
const teamStore = useTeamStore();
const currentTeam = computed(() => teamStore.current_team);
</script>

<template>
  <div class="container mx-auto p-4 text-gray-800 dark:text-gray-300">
    <div
      class="mx-auto p-2 js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['google-search-console']"
    >
      <div class="p-2 font-inter font-medium text-base md:text-lg">
        SITE OVERVIEW
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <!-- Brand vs Non-Brand Clicks -->
        <div class="shadow-lg rounded-lg overflow-hidden">
          <BrandVsNonBrandClicks />
        </div>

        <!-- Average Positions -->
        <div class="shadow-lg rounded-lg overflow-hidden">
          <AvgPosition />
        </div>

        <!-- Brand vs Non-Brand Impressions -->
        <div class="shadow-lg rounded-lg overflow-hidden">
          <BrandVsNonBrandImpressions />
        </div>
      </div>
    </div>
    <div
      class="mx-auto p-2 js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['google-analytics-data-api']"
    >
      <div class="p-2 font-inter font-medium text-base md:text-lg">
        CITYWISE OVERVIEW
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <!-- City Wise User Trend -->
        <div class="shadow-lg rounded-lg overflow-hidden">
          <CityWiseUserTrend />
        </div>

        <!-- City Wise Engaged Sessions -->
        <div class="shadow-lg rounded-lg overflow-hidden">
          <CityWiseEngagedSessions />
        </div>
      </div>
    </div>

    <div
      class="mx-auto p-2 js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['google-analytics-data-api']"
    >
      <div class="p-2 font-inter font-medium text-base md:text-lg">
        MARKETING CHANNEL
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <!-- Channel Wise User Trend -->
        <div class="shadow-lg rounded-lg overflow-hidden md:col-span-2">
          <ChannelWiseUserTrend />
        </div>
      </div>
    </div>
    <div
      class="mx-auto p-2 js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['google-analytics-data-api']"
    >
      <div class="p-2 font-inter font-medium text-base md:text-lg">
        TRAFFIC OVERVIEW
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <!-- Landing Page Table -->
        <div class="shadow-lg rounded-lg overflow-hidden">
          <LandingPageTable />
        </div>

        <!-- Device Trend Chart -->
        <div class="shadow-lg rounded-lg overflow-hidden">
          <DeviceTrendChart />
        </div>
      </div>
    </div>
    <div
      class="mx-auto p-2 js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['google-ads']"
    >
      <div class="p-2 font-inter font-medium text-base md:text-lg">
        KEYWORD OVERVIEW
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <!-- Keyword Level Spend -->
        <div class="shadow-lg rounded-lg overflow-hidden">
          <KeywordLevelSpend />
        </div>

        <!-- Keyword Level Conversion -->
        <div class="shadow-lg rounded-lg overflow-hidden">
          <KeywordLevelConversion />
        </div>
      </div>
    </div>

    <div
      class="mx-auto p-2 js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['youtube-analytics']"
    >
      <div class="p-2 font-inter font-medium text-base md:text-lg">
        YouTube Overview
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div class="shadow-lg rounded-lg overflow-hidden">
          <DeviceDistribution />
        </div>

        <div class="shadow-lg rounded-lg overflow-hidden">
          <GenderDistribution />
        </div>
        <div class="shadow-lg rounded-lg overflow-hidden">
          <AgeGroupDistribution />
        </div>

        <div class="shadow-lg rounded-lg overflow-hidden">
          <CountryDistribution />
        </div>
      </div>
    </div>
    <div
      class="mx-auto p-2 js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['instagram']"
    >
      <div class="p-2 font-inter font-medium text-base md:text-lg">
        Instagram Overview
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div class="shadow-lg rounded-lg overflow-hidden">
          <AgeGroupDistributionInsta />
        </div>

        <div class="shadow-lg rounded-lg overflow-hidden">
          <FollowerDistribution />
        </div>
        <div class="shadow-lg rounded-lg overflow-hidden md:col-span-2">
          <InteractionsInstagram />
        </div>
      </div>
    </div>
    <div
      class="mx-auto p-2 js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['google-ads']"
    >
      <div class="p-2 font-inter font-medium text-base md:text-lg">
        CAMPAIGN OVERVIEW
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <!-- Campaign Level Spend -->
        <div class="shadow-lg rounded-lg overflow-hidden">
          <CampaignLevelSpend />
        </div>

        <!-- Campaign Level Conversion -->
        <div class="shadow-lg rounded-lg overflow-hidden">
          <CampaignLevelConversion />
        </div>

        <!-- Campaign with Lowest Cost per Conversion -->
        <div class="shadow-lg rounded-lg overflow-hidden">
          <CampaignWithLowCostPerConversionTable />
        </div>
      </div>
    </div>
  </div>
</template>
