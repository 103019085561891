<script setup>
import SectionBorder from '@/components/SectionBorder.vue';
import UpdateProfileInformationForm from '@/pages/Profile/Partials/UpdateProfileInformationForm.vue';
import { useUserStore } from '@/stores/userStore';
import { computed } from 'vue';
import UpdatePasswordForm from './Partials/UpdatePasswordForm.vue';
import PageHeading from '@/components/PageHeading.vue';

const userStore = useUserStore();
const user = computed(() => {
  return {
    email: userStore?.userData?.email,
  };
});
</script>

<template>
  <div class="">
    <PageHeading title="Profile" />
    <div class="py-10 sm:px-6 lg:px-8">
      <div><UpdateProfileInformationForm /> <SectionBorder /></div>
      <div class="mt-10 sm:mt-0">
        <UpdatePasswordForm :email="user.email || ''" />
        <SectionBorder />
      </div>
    </div>
  </div>
</template>
