<template>
  <div
    class="bg-gray-100 dark:bg-black px-3 pb-6 pt-3 dark:text-white grid grid-cols-1 md:grid-cols-2 gap-5 h-full"
  >
    <div
      class="flex flex-col js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['google-ads']"
    >
      <h2 class="text-lg font-semibold mb-3">ADS PERFORMANCE METRICS</h2>
      <div
        class="flex-1 grid grid-cols-3 grid-rows-5 gap-3"
        @dragover.prevent
        @dragenter.prevent
      >
        <div class="col-span-2 row-span-2 col-start-2 row-start-2">
          <CPCVsCTRGADS />
        </div>
        <div class="col-span-2 row-span-2 row-start-4">
          <ImpressionAndClick />
        </div>

        <div
          v-for="(metric, index) in googleAdsMetrics"
          :key="metric.id"
          draggable="true"
          @dragstart="onDragStart($event, index, 'googleAds')"
          @dragend="onDragEnd"
          @drop="onDrop($event, index, 'googleAds')"
          :class="{
            'opacity-50': isDraggingGoogleAds,
          }"
          @dragover="onDragOver($event)"
        >
          <component :is="metric.component" />
        </div>
      </div>
    </div>

    <div
      class="flex flex-col js-show-on-scroll animate-fade-up"
      v-if="
        currentTeam?.connections['google-analytics-data-api'] ||
        currentTeam?.connections['google-search-console']
      "
    >
      <h2 class="text-lg font-semibold mb-3">ORGANIC METRICS</h2>
      <div
        class="flex-1 grid grid-cols-3 grid-rows-5 gap-3"
        @dragover.prevent
        @dragenter.prevent
      >
        <div class="col-span-2 row-span-2 col-start-2 row-start-2">
          <SessionTypeDonut />
        </div>
        <div class="col-span-2 row-span-2 row-start-4">
          <UserMatrix />
        </div>
        <div
          v-for="(metric, index) in organicMetrics"
          :key="metric.id"
          draggable="true"
          @dragstart="onDragStart($event, index, 'organicMetrics')"
          @dragend="onDragEnd"
          @drop="onDrop($event, index, 'organicMetrics')"
          :class="{
            'opacity-50': isDraggingOrganic,
          }"
          @dragover="onDragOver($event)"
        >
          <component :is="metric.component" />
        </div>
      </div>
    </div>
  </div>
  <!-- Socials -->
  <div
    class="px-3 text-xl dark:text-white font-bold"
    v-if="
      currentTeam?.connections['youtube-analytics'] ||
      currentTeam?.connections['instagram'] ||
      currentTeam?.connections['facebook-marketing'] ||
      currentTeam?.connections['tiktok-marketing']
    "
  >
    Social Platforms
  </div>
  <div
    class="bg-gray-100 dark:bg-black p-3 dark:text-white grid grid-cols-1 md:grid-cols-2 gap-5 h-full"
  >
    <div
      class="flex flex-col js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['youtube-analytics']"
    >
      <h2 class="text-lg font-semibold mb-3">YouTube Metrics</h2>
      <div class="flex-1 grid grid-cols-3 grid-rows-3 gap-3">
        <div><ViewsYt /></div>
        <div><WatchTimeYT /></div>
        <div><SubscriberCountYt /></div>
        <div><EngagementRateYT /></div>
        <div class="col-start-1 row-start-3">
          <AvgViewDurationYT />
        </div>
        <div class="col-span-2 row-span-2 col-start-2 row-start-2">
          <EngagementDistribution />
        </div>
      </div>
    </div>
    <div
      class="flex flex-col js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['instagram']"
    >
      <h2 class="text-lg font-semibold mb-3">Instagram Metrics</h2>
      <div class="flex-1 grid grid-cols-3 grid-rows-3 gap-3">
        <div><ReachInsta /></div>
        <div><ImpressionsInsta /></div>
        <div><ProfileViewsInsta /></div>
        <div><TotalEngagementsInsta /></div>
        <div class="col-start-1 row-start-3">
          <EngagementRateInsta />
        </div>
        <div class="col-span-2 row-span-2 col-start-2 row-start-2">
          <EngagementVSReachInsta />
        </div>
      </div>
    </div>
    <div
      class="flex flex-col js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['facebook-marketing']"
    >
      <h2 class="text-lg font-semibold mb-3">Facebook Metrics</h2>
      <div class="flex-1 grid grid-cols-3 grid-rows-3 gap-3">
        <div><ImpressionsFB /></div>
        <div><ClicksFB /></div>
        <div><CTRFB /></div>
        <div><CPCFB /></div>
        <div class="col-start-1 row-start-3">
          <ReachFB />
        </div>
        <div class="col-span-2 row-span-2 col-start-2 row-start-2">
          <SpentFB />
        </div>
      </div>
    </div>
    <div
      class="flex flex-col js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['linkedin-pages']"
    >
      <h2 class="text-lg font-semibold mb-3">LinkedIn Page Metrics</h2>
      <div class="flex-1 grid grid-cols-3 grid-rows-3 gap-3">
        <div><LikesLinkedInPage /></div>
        <div><ClicksLinkedInPage /></div>
        <div><ImpressionsLinkedInPage /></div>
        <div><EngagementLinkedInPage /></div>
        <div class="col-start-1 row-start-3">
          <FollowersLinkedInPage />
        </div>
        <div class="col-span-2 row-span-2 col-start-2 row-start-2">
          <FollowerDistributionLinkedInPage />
        </div>
      </div>
    </div>
    <div
      class="flex flex-col js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['tiktok-marketing']"
    >
      <h2 class="text-lg font-semibold mb-3">TikTok Marketting Metrics</h2>
      <div class="flex-1 grid grid-cols-3 grid-rows-3 gap-3">
        <div><CPCTikTok /></div>
        <div><ConversionsTikTok /></div>
        <div><ImpressionsTikTok /></div>
        <div><ReachTikTok /></div>
        <div class="col-start-1 row-start-3">
          <EngagementTiktok />
        </div>
        <div class="col-span-2 row-span-2 col-start-2 row-start-2">
          <FollowersGainedTikTok />
        </div>
      </div>
    </div>
  </div>
  <!-- Ad Platforms -->
  <div
    class="px-3 pt-6 text-xl dark:text-white font-bold"
    v-if="currentTeam?.connections['amazon-ads']"
  >
    Ad Platforms
  </div>
  <div
    class="bg-gray-100 dark:bg-black p-3 dark:text-white grid grid-cols-1 md:grid-cols-2 gap-5 h-full"
  >
    <div
      class="flex flex-col js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['linkedin-ads']"
    >
      <h2 class="text-lg font-semibold mb-3">LinkedIn Ads Metrics</h2>
      <div class="flex-1 grid grid-cols-3 grid-rows-3 gap-3">
        <div><ClicksLinkedIn /></div>
        <div><ImpressionsLinkedIn /></div>
        <div><SpendLinkedIn /></div>
        <div><ROASLinkedIn /></div>
        <div class="col-start-1 row-start-3">
          <ConversionsLinkedIn />
        </div>
        <div class="col-span-2 row-span-2 col-start-2 row-start-2">
          <CPCVSCTRLinkedIn />
        </div>
      </div>
    </div>
    <div
      class="flex flex-col js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['amazon-ads']"
    >
      <h2 class="text-lg font-semibold mb-3">Amazon Ads Metrics</h2>
      <div class="flex-1 grid grid-cols-3 grid-rows-3 gap-3">
        <div><ClicksAmazonAds /></div>
        <div><ConversionsAmazonAds /></div>
        <div><ImpressionsAmazonAds /></div>
        <div><ROASAmazonAds /></div>
        <div class="col-start-1 row-start-3">
          <SpendAmazonAds />
        </div>
        <div class="col-span-2 row-span-2 col-start-2 row-start-2">
          <CPCVsCTRAmazonAds />
        </div>
      </div>
    </div>
    <div
      class="flex flex-col js-show-on-scroll animate-fade-up"
      v-if="currentTeam?.connections['blinkit']"
    >
      <h2 class="text-lg font-semibold mb-3">Blinkit Metrics</h2>
      <div class="flex-1 grid grid-cols-3 grid-rows-3 gap-3">
        <div><CPMBlinkit /></div>
        <div><ImpressionsBlinkit /></div>
        <div><NewUserAcquiredBlinkit /></div>
        <div><ROASBlinkit /></div>
        <div class="col-start-1 row-start-3">
          <SalesBlinkit />
        </div>
        <div class="col-span-2 row-span-2 col-start-2 row-start-2">
          <BudgetConsumed />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, shallowRef } from 'vue';
import { useTeamStore } from '@/stores/teamStore';

import CPCVsCTRGADS from '@/components/charts/google-ads/CPCVsCTR.vue';
import ImpressionAndClick from '@/components/charts/google-ads/ImpressionAndClick.vue';
import SpentFB from '@/components/charts/facebook/SpentFB.vue';
import SessionTypeDonut from '@/components/charts/google-analytics/SessionTypeDonut.vue';
import UserMatrix from '@/components/charts/google-analytics/UserMatrix.vue';
import EngagementDistribution from '@/components/charts/youtube/EngagementDistribution.vue';
import EngagementVSReachInsta from '@/components/charts/instagram/EngagementVSReachInsta.vue';
import {
  LikesLinkedInPage,
  ClicksLinkedInPage,
  ImpressionsLinkedInPage,
  EngagementLinkedInPage,
  FollowersLinkedInPage,
} from '@/components/dashboard_metrics/linkedin_pages';
import {
  CPMBlinkit,
  ImpressionsBlinkit,
  NewUserAcquiredBlinkit,
  ROASBlinkit,
  SalesBlinkit,
} from '@/components/dashboard_metrics/blinkit';
import {
  CPCTikTok,
  ConversionsTikTok,
  ImpressionsTikTok,
  ReachTikTok,
  EngagementTiktok,
} from '@/components/dashboard_metrics/tiktok_marketting';
import {
  ImpressionsFB,
  ClicksFB,
  CTRFB,
  ReachFB,
  CPCFB,
} from '@/components/dashboard_metrics/facebook';
import {
  ClickDateGADS,
  ConversionDateGADS,
  CPCDateGADS,
  CRDateGADS,
  CTRDateGADS,
  ImpDateGADS,
  TotalSpentDateGADS,
} from '@/components/dashboard_metrics/google_ads';
import {
  EngagedSessionDateGSTAT,
  NewUserDateGSTAT,
  SessionDateGSTAT,
  TotalUserDateGSTAT,
} from '@/components/dashboard_metrics/google_analytics';
import {
  ClicksDateGSC,
  CTRDateMatrixGSC,
  ImpDateGSC,
} from '@/components/dashboard_metrics/google_search_console';
import {
  EngagementRateInsta,
  ReachInsta,
  ImpressionsInsta,
  ProfileViewsInsta,
  TotalEngagementsInsta,
} from '@/components/dashboard_metrics/instagram';
import {
  AvgViewDurationYT,
  ViewsYt,
  EngagementRateYT,
  SubscriberCountYt,
  WatchTimeYT,
} from '@/components/dashboard_metrics/youtube_analytics';
import {
  ClicksLinkedIn,
  ConversionsLinkedIn,
  ImpressionsLinkedIn,
  ROASLinkedIn,
  SpendLinkedIn,
} from '@/components/dashboard_metrics/linkedin_ads';
import {
  ClicksAmazonAds,
  ConversionsAmazonAds,
  ImpressionsAmazonAds,
  ROASAmazonAds,
  SpendAmazonAds,
} from '@/components/dashboard_metrics/amazon_ads';
import CPCVsCTRAmazonAds from '@/components/charts/amazon-ads/CPCVsCTRAmazonAds.vue';
import FollowersGainedTikTok from '@/components/charts/tiktok/FollowersGainedTikTok.vue';
import BudgetConsumed from '@/components/charts/blinkit/BudgetConsumed.vue';
import FollowerDistributionLinkedInPage from '@/components/charts/linkedin-pages/FollowerDistributionLinkedInPage.vue';
import CPCVSCTRLinkedIn from '@/components/charts/linkedin-ads/CPCVSCTRLinkedIn.vue';
const teamStore = useTeamStore();
const currentTeam = computed(() => teamStore.current_team);
const isDraggingOrganic = ref(false);
const isDraggingGoogleAds = ref(false);

const isDraggedOver = ref(false);
const draggingList = ref('');

const organicMetrics = shallowRef([
  { id: 'imp-gsc', component: ImpDateGSC },
  { id: 'clk-gsc', component: ClicksDateGSC },
  { id: 'crr-gsc', component: CTRDateMatrixGSC },
  { id: 'session-gstat', component: SessionDateGSTAT },
  { id: 'eng-session-gstat', component: EngagedSessionDateGSTAT },
  { id: 'new-user-gstat', component: NewUserDateGSTAT },
  { id: 'total-user-gstat', component: TotalUserDateGSTAT },
]);

const googleAdsMetrics = shallowRef([
  { id: 'total-spent', component: TotalSpentDateGADS },
  { id: 'conversion-date', component: ConversionDateGADS },
  { id: 'cr-date', component: CRDateGADS },
  { id: 'cpc-date', component: CPCDateGADS },
  { id: 'ctr-date', component: CTRDateGADS },
  { id: 'imp-date', component: ImpDateGADS },
  { id: 'clk-date', component: ClickDateGADS },
]);

const onDragStart = (event, index, listName) => {
  if (listName === 'googleAds') {
    isDraggingGoogleAds.value = true;
  } else if (listName === 'organicMetrics') {
    isDraggingOrganic.value = true;
  }
  draggingList.value = listName;
  event.dataTransfer.setData('index', index);
  event.target.classList.add('dragging');
};

const onDragEnd = () => {
  isDraggingGoogleAds.value = false;
  isDraggingOrganic.value = false;
  isDraggedOver.value = false;

  document
    .querySelectorAll('.dragging')
    .forEach((el) => el.classList.remove('dragging'));
};

const onDrop = (event, dropIndex, listName) => {
  const dragIndex = event.dataTransfer.getData('index');
  let draggedItem;

  if (listName === 'googleAds') {
    draggedItem = googleAdsMetrics.value[dragIndex];
    googleAdsMetrics.value.splice(dragIndex, 1);
    googleAdsMetrics.value.splice(dropIndex, 0, draggedItem);
  } else if (listName === 'organicMetrics') {
    draggedItem = organicMetrics.value[dragIndex];
    organicMetrics.value.splice(dragIndex, 1);
    organicMetrics.value.splice(dropIndex, 0, draggedItem);
  }
};

const onDragOver = (event) => {
  isDraggedOver.value = true;
  event.preventDefault();
};
</script>

<style scoped>
.dragging {
  opacity: 0.5;
}
</style>
