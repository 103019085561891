<template>
  <MetricCard
    apiEndpoint="/instagram/engagement-rate"
    metricName="ENGAGEMENT RATE"
    :formatData="formatData"
    srcName="Instagram"
  />
</template>

<script setup>
import MetricCard from '@/components/MetricCard.vue';
const formatData = (data) => data.data + '%';
</script>
