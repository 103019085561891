<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-4 px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 min-h-full flex-grow max-h-[420px] md:max-h-[500px]"
  >
    <!-- Header section with title -->
    <div class="flex justify-between flex-wrap">
      <div class="text-sm md:text-base font-semibold mb-2 dark:text-white">
        Follower Distribution (Age Group)
      </div>
      <div
        class="flex text-xs md:text-sm items-center text-gray-500 dark:text-gray-300 px-4 gap-1 mb-2"
      >
        <Calendar :size="16" />
        All Time
      </div>
    </div>
    <!-- Loader -->
    <div v-if="loading" class="flex flex-1 justify-center">
      <StyledLoader />
    </div>
    <!-- Chart Section -->
    <div v-else class="flex-1">
      <div v-if="chartSeries[0]?.data.length">
        <apexchart type="bar" :options="chartOptions" :series="chartSeries" />
      </div>
      <div v-else class="flex justify-center items-center text-base md:text-lg">
        No activity.
      </div>
    </div>
    <!-- Source Badge -->
    <SourceBadge
      src="/images/instagram.png"
      alt="Instagram"
      label="Instagram"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import Api from '@/utils/ApiClient';
import { useTeamStore } from '@/stores/teamStore';
import { Calendar } from 'lucide-vue-next';
import StyledLoader from '@/components/StyledLoader.vue';
import SourceBadge from '@/components/SourceBadge.vue';
import { formatNumber } from '@/utils/helperFunctions';

const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);

const loading = ref(false);
const chartSeries = ref([]);
const chartLabels = ref([]);
const totalFollowers = ref();
const colors = ['#60a5fa', '#FF69B4', '#FF7F50'];

const chartOptions = computed(() => ({
  chart: {
    id: 'age-group-chart',
    type: 'bar',
    toolbar: { show: false },
    zoom: { enabled: false },
  },
  colors: colors,
  xaxis: {
    categories: chartLabels.value,
    title: { text: 'Age Groups' },
  },
  yaxis: {
    labels: {
      show: false,
    },
    title: { text: 'Follower Count' },
  },
  grid: {
    show: false,
  },
  tooltip: {
    theme: 'dark',
    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      const xValue = w.globals.labels[dataPointIndex];
      const yValue = series[seriesIndex][dataPointIndex];
      const percent = ((yValue / totalFollowers.value) * 100).toFixed(1);
      return `
        <div class="tooltip-custom  text-white  rounded">
          <div class='text-center bg-black py-2 border-b'>Age : <strong>${xValue}</strong></div>
          <div class='py-1 px-2'><strong>Followers :</strong> ${formatNumber(yValue)}</div>
          <div class='py-1 px-2'><strong>Percent :</strong> ${percent}% </div>
        </div>
      `;
    },
  },
  noData: {
    text: 'No data available',
  },
  plotOptions: {
    bar: {
      horizontal: false,
    },
  },
  dataLabels: { enabled: false },
}));

const fetchData = async () => {
  try {
    loading.value = true;
    const response = await Api.get(
      `/instagram-insights/follower-demographics-age`,
    );

    const items = response.data?.items;
    if (items && items.data && items.columns) {
      chartLabels.value = items.columns;

      totalFollowers.value = items.data.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0,
      );
      chartSeries.value = [
        {
          name: 'Age Groups',
          data: items.data,
        },
      ];
    } else {
      chartLabels.value = [];
      chartSeries.value = [];
    }
  } catch (error) {
    chartLabels.value = [];
    chartSeries.value = [];
  } finally {
    loading.value = false;
  }
};

watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam) {
      currentTeam.value = newTeam;
      fetchData();
    }
  },
  { immediate: true, deep: true },
);
</script>
