<template>
  <section
    class="py-6 px-4 md:px-6 flex flex-col min-h-screen justify-center items-center bg-white border border-gray-200 dark:bg-gray-900 dark:border-gray-700 rounded-2xl shadow-lg"
  >
    <header class="text-center mb-6">
      <h1 class="text-2xl font-bold text-gray-800 dark:text-white">
        You've Been Invited to Join a Team!
      </h1>
      <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
        Accept or decline the invitation to collaborate with your team.
      </p>
    </header>

    <span
      v-if="loading"
      class="justify-center inline-flex w-full animate-spin text-lg"
      >.</span
    >
    <div
      v-else
      class="flex flex-col gap-4 md:flex-row md:justify-center items-center"
    >
      <button
        class="w-full md:w-auto text-xs font-medium text-gray-800 dark:text-gray-300 underline hover:text-gray-600 dark:hover:text-gray-400 transition-colors duration-300"
        :disabled="loading"
        @click="openConfirmationModal"
      >
        Decline Invitation
      </button>

      <button
        class="w-full md:w-auto text-xs font-medium bg-gray-800 rounded-lg hover:bg-gray-700 text-white px-6 py-2.5 transition-colors duration-300"
        :disabled="loading"
        @click="openAcceptConfirmationModal"
      >
        Accept Invitation
      </button>
    </div>
  </section>

  <ConfirmationModal
    :show="showDeclineConfirmationModal"
    @close="showDeclineConfirmationModal = false"
  >
    <template #title>Confirm Decline</template>
    <template #content>
      Are you sure you want to decline this invitation? You won't be able to
      join the team unless invited again.
    </template>
    <template #footer>
      <button
        class="bg-gray-200 text-gray-700 px-4 py-2 mr-2 rounded"
        @click="showDeclineConfirmationModal = false"
      >
        Cancel
      </button>
      <button
        class="bg-red-600 text-white px-4 py-2 rounded"
        :disabled="loading"
        @click="confirmDecline"
      >
        <span v-if="loading">Declining...</span>
        <span v-else>Confirm</span>
      </button>
    </template>
  </ConfirmationModal>

  <ConfirmationModal
    :show="showAcceptConfirmationModal"
    @close="showAcceptConfirmationModal = false"
  >
    <template #title>Confirm Accept</template>
    <template #content>
      Are you sure you want to accept the invitation? You'll be added to the
      team and gain access to its resources.
    </template>
    <template #footer>
      <button
        class="bg-gray-200 text-gray-700 px-4 py-2 mr-2 rounded"
        @click="showAcceptConfirmationModal = false"
      >
        Cancel
      </button>
      <button
        class="bg-green-600 text-white px-4 py-2 rounded"
        :disabled="loading"
        @click="acceptInvite"
      >
        <span v-if="loading">Accepting...</span>
        <span v-else>Confirm</span>
      </button>
    </template>
  </ConfirmationModal>
</template>

<script setup>
import ConfirmationModal from '@/components/ConfirmationModal.vue';
import { useTeamStore } from '@/stores/teamStore';
import Api from '@/utils/ApiClient';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';

const showDeclineConfirmationModal = ref(false);
const showAcceptConfirmationModal = ref(false);
const loading = ref(false);
const route = useRoute();
const team_id = route.params.team_id;
const token = route.params.token;
const router = useRouter();
const teamStore = useTeamStore();

const openConfirmationModal = () => {
  showDeclineConfirmationModal.value = true;
};

const openAcceptConfirmationModal = () => {
  showAcceptConfirmationModal.value = true;
};

const acceptInvite = async () => {
  try {
    loading.value = true;
    const response = await Api.post('/invitation/link-account', {
      team_id,
      status: 'accepted',
      token,
    });
    if (response.data.message) {
      teamStore.refetchNewTeamsFlag = true;
      router.push('/dashboard').then(() =>
        toast.success(
          response.data.message || 'Invitation accepted successfully!',
          {
            position: 'top-center',
            autoClose: 2000,
            transition: 'zoom',
          },
        ),
      );
    }
  } catch (error) {
    toast.error(
      error.response?.data?.detail || 'Failed to accept the invite.',
      {
        autoClose: 2000,
        onClose: () => {
          loading.value = false;
        },
      },
    );
  } finally {
    showAcceptConfirmationModal.value = false;
    loading.value = false;
  }
};

const confirmDecline = async () => {
  try {
    loading.value = true;
    const response = await Api.post('/invitation/link-account', {
      team_id,
      status: 'declined',
      token,
    });
    if (response.status === 200) {
      router.push('/dashboard').then(() =>
        toast.success(
          response.data.message || 'Invitation declined successfully!',
          {
            position: 'top-center',
            autoClose: 2000,
            transition: 'zoom',
          },
        ),
      );
    }
  } catch (error) {
    toast.error(
      error.response?.data?.detail || 'Failed to decline the invite.',
      {
        autoClose: 2000,
        onClose: () => {
          loading.value = false;
        },
      },
    );
  } finally {
    showDeclineConfirmationModal.value = false;
    loading.value = false;
  }
};
</script>
