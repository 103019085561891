<template>
  <div
    class="grid grid-rows-10 dark:text-white min-h-screen bg-gray-100 dark:bg-black"
  >
    <div class="row-span-1 bg-white dark:bg-gray-950">
      <div
        class="flex text-lg gap-2 bg-gray-50 dark:bg-black items-center font-semibold h-full w-full py-2 px-4 md:px-6 md:py-4 border-y border-gray-300 dark:border-gray-600"
      >
        <button
          class="rotate-90 p-3 rounded-full bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 transition duration-200"
          @click.prevent="goBackToTeam"
        >
          <ChevronsDown />
        </button>

        <div
          class="flex items-center justify-center size-12 bg-white rounded-full shadow-lg border border-gray-300 dark:border-gray-600"
        >
          <img
            src="/images/linkedin.png"
            alt="LinkedIn"
            class="w-8 h-8 object-contain"
          />
        </div>
        <div class="text-gray-800 dark:text-gray-200 text-xl">
          LinkedIn Pages
        </div>
      </div>
    </div>

    <div class="row-span-9 mx-auto flex flex-col items-center justify-center">
      <div
        v-if="loading"
        class="flex flex-col items-center justify-center gap-4 h-full"
      >
        <Loader />
        <p
          class="text-base text-gray-700 dark:text-gray-300 text-center font-medium"
        >
          This process may take a few moments.
        </p>
      </div>
      <div
        class="w-full max-w-lg bg-white dark:bg-gray-950 rounded-2xl p-6"
        v-else
      >
        <h2
          class="text-2xl font-bold text-gray-900 dark:text-gray-100 text-center mb-4"
        >
          Connect Your LinkedIn Page
        </h2>
        <p class="text-sm text-gray-600 dark:text-gray-400 text-center mb-6">
          To synchronize your LinkedIn data, please enter your organization ID.
        </p>
        <div class="mb-6">
          <InputLabel
            for="organizationID"
            value="Organization ID"
            class="text-sm text-gray-700 dark:text-gray-300"
          />
          <TextInput
            id="organizationID"
            v-model="organizationID"
            type="text"
            placeholder="Enter Organization ID"
            class="block w-full mt-2 p-3 border border-gray-300 dark:border-gray-600 rounded-lg bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-800 dark:focus:ring-gray-400 transition duration-200"
          />
          <InputError :message="errorMessage" class="mt-2 text-red-500" />
        </div>
        <div class="grid grid-cols-2 gap-2">
          <SecondaryButton @click="goBackToTeam">
            Cancel setup
          </SecondaryButton>
          <PrimaryButton @click="submitOrganization"> Submit </PrimaryButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import InputError from '@/components/InputError.vue';
import InputLabel from '@/components/InputLabel.vue';
import Loader from '@/components/Loader.vue';
import PrimaryButton from '@/components/PrimaryButton.vue';
import SecondaryButton from '@/components/SecondaryButton.vue';
import TextInput from '@/components/TextInput.vue';
import Api from '@/utils/ApiClient';
import { ID_VALIDATION_ERROR } from '@/utils/constantData';
import { processId, validateID } from '@/utils/helperFunctions';
import { ChevronsDown } from 'lucide-vue-next';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { toast } from 'vue3-toastify';

const code = ref();
const state = ref();
const route = useRoute();
const loading = ref(false);
const connected = ref(false);
const router = useRouter();
const organizationID = ref('');
const errorMessage = ref('');
const accessToken = ref('');

const validateOrganizationId = () => {
  organizationID.value = processId(organizationID.value);
  return validateID(organizationID.value);
};

const goBackToTeam = () => {
  router.push('/settings/team/connections');
};

const establishConnection = async () => {
  try {
    loading.value = true;

    const response = await Api.post(
      `/airbyte/linkedin-page-connect`,
      {
        auth_code: code.value,
      },
      {
        params: {
          csrf_state_key: state.value,
        },
      },
    );

    if (response.data.message) {
      accessToken.value = response.data.access_token;
      toast.success(response.data.message);
    }
  } catch (error) {
    toast.error(error.response.data.detail || 'Account Connection Failed!');
  } finally {
    loading.value = false;
  }
};

const submitOrganization = async () => {
  if (!organizationID.value) {
    errorMessage.value = 'Organization ID is required';
    return;
  }
  if (!validateOrganizationId()) {
    toast.error(ID_VALIDATION_ERROR);
    return;
  }
  try {
    loading.value = true;
    const response = await Api.post(`/airbyte/linkedin-page`, {
      org_id: organizationID.value,
      secret_id: accessToken.value,
    });

    if (response.data.message) {
      connected.value = true;
      toast.success(
        response.data.message || 'Organization connected successfully!',
        {
          onClose: () => {
            goBackToTeam();
          },
        },
      );
    }
  } catch (error) {
    toast.error(error.response.data.detail || 'Account Connection Failed!');
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  if (route.query.code) {
    code.value = route.query.code;
    state.value = route.query.state;
    establishConnection();
  }
});
</script>
