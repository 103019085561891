<template>
  <MetricCard
    apiEndpoint="/youtube-analytics/views"
    metricName="Views"
    :formatData="formatUsers"
    srcName="YouTube Analytics"
  />
</template>

<script setup>
import MetricCard from '@/components/MetricCard.vue';

const formatUsers = (data) => data.data;
</script>
