<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-2 text-center px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 h-full"
  >
    <!-- Header section with toggle button and title -->
    <div class="text-left">Spend</div>

    <div v-if="loading" class="flex flex-1 justify-center">
      <StyledLoader />
    </div>
    <div v-else class="flex-1 grid">
      <div
        class="my-2 mx-auto flex-grow flex justify-center items-center"
        v-if="chartSeries[0] && chartSeries[0].data"
      >
        <apexchart
          type="line"
          :options="chartOptions"
          :series="chartSeries"
          class="flex-1"
        ></apexchart>
      </div>
      <div v-else class="flex justify-center items-center text-base md:text-lg">
        No activity.
      </div>
    </div>
    <SourceBadge
      src="/images/facebook_ads.png"
      alt="Facebook Ads"
      label="Facebook Ads"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import Api from '@/utils/ApiClient';
import { useTeamStore } from '@/stores/teamStore';
import StyledLoader from '@/components/StyledLoader.vue';
import SourceBadge from '@/components/SourceBadge.vue';
import { useDateStore } from '@/stores/dateStore';
import {
  formatChartLabel,
  formatNumber,
  groupChartData,
} from '@/utils/helperFunctions';

const teamStore = useTeamStore();
const currentTeam = ref(teamStore.current_team);
const dateStore = useDateStore();
const loading = ref(false);
const categories = ref([]);
const chartSeries = ref([
  {
    name: 'Spent',
    data: [],
  },
]);

const fetchData = async () => {
  try {
    loading.value = true;
    const response = await Api.get(`/facebook/spends`, {
      params: {
        start_date: dateStore.dashboardDate.currentStart,
        end_date: dateStore.dashboardDate.currentEnd,
      },
    });

    const rawData = JSON.parse(response.data?.items.data);

    rawData.sort((a, b) => new Date(a[0]) - new Date(b[0]));

    const timestamps = rawData.map(([date]) => new Date(date));
    const startDate = new Date(timestamps[0]);
    const endDate = new Date(timestamps[timestamps.length - 1]);
    const dateDiffInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

    let groupBy = 'day';
    if (dateDiffInDays > 90) groupBy = 'month';
    else if (dateDiffInDays > 30) groupBy = 'week';

    const groupedData = groupChartData(rawData, groupBy, [1]);
    categories.value = groupedData.map((item) =>
      formatChartLabel(item.rawDate, groupBy),
    );

    chartSeries.value = [
      {
        name: 'Spent',
        data: groupedData.map((item) => item.values[0]),
      },
    ];
  } catch (error) {
    categories.value = [];
    chartSeries.value = [
      {
        name: 'Spent',
        data: [],
      },
    ];
  } finally {
    loading.value = false;
  }
};

const chartOptions = computed(() => ({
  chart: {
    id: 'spent-fb-chart',
    type: 'line',
    toolbar: {
      show: false,
    },
    zoom: { enabled: false },
  },
  colors: ['#3b82f6'],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  xaxis: {
    categories: categories.value,
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  grid: {
    show: false,
  },
  tooltip: {
    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      const xValue = categories.value[dataPointIndex];
      const yValue = series[seriesIndex][dataPointIndex];

      return `
        <div class="tooltip-custom  text-white  rounded">
          <div class='text-center bg-black py-2 border-b'><strong>${xValue}</strong></div>
          <div class='py-1 px-2'>Total Spent :<strong> ₹ ${formatNumber(yValue)}</strong></div>
          
        </div>
      `;
    },
    theme: 'dark',
  },
  noData: {
    text: 'No Activity',
  },
  legend: {
    position: 'bottom',
    labels: {
      useSeriesColors: true,
    },
  },
}));
watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam) {
      currentTeam.value = newTeam;
      fetchData();
    }
  },
  { immediate: true, deep: true },
);
watch(() => dateStore.dashboardDate, fetchData, {
  immediate: true,
  deep: true,
});
</script>
