<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-4 px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 min-h-full flex-grow max-h-[420px]"
  >
    <div class="flex justify-between flex-wrap">
      <div class="text-base md:text-xl font-semibold mb-2 dark:text-white">
        Clicks Distribution
      </div>
      <div
        class="flex text-sm items-center text-gray-500 dark:text-gray-300 px-4 gap-1 mb-2"
      >
        <Calendar :size="16" />
        {{ formattedStartDate }} - {{ formattedEndDate }}
      </div>
    </div>
    <div v-if="loading" class="flex flex-1 justify-center">
      <StyledLoader />
    </div>
    <div v-else class="flex-1 grid">
      <div
        v-if="brandData && nonBrandData"
        class="flex justify-center items-center flex-col lg:flex-row"
      >
        <apexchart
          type="donut"
          :options="chartOptions"
          :series="chartSeries"
          class=""
        ></apexchart>
        <div
          class="text-xs md:text-base lg:text-lg flex lg:flex-col gap-2 md:gap-0 flex-wrap justify-center"
        >
          <span
            v-for="(item, index) in labelData"
            :key="index"
            :style="{ color: colors[index] }"
          >
            ● {{ item }}
          </span>
        </div>
      </div>
      <div v-else class="flex justify-center items-center text-base md:text-lg">
        {{ error }}
      </div>
    </div>
    <SourceBadge
      src="/images/google-search-console.png"
      alt="Google Search Console"
      label="Google Search Console"
      :imageClass="'h-3 w-auto'"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import Api from '@/utils/ApiClient';
import { useTeamStore } from '@/stores/teamStore';
import { formatNumber } from '@/utils/helperFunctions';
import { useDateStore } from '@/stores/dateStore';
import { Calendar } from 'lucide-vue-next';
import StyledLoader from '@/components/StyledLoader.vue';
import SourceBadge from '@/components/SourceBadge.vue';

const teamStore = useTeamStore();
const dateStore = useDateStore();
const currentTeam = ref(teamStore.current_team);
const brandData = ref();
const nonBrandData = ref();
const labelData = ref();
const loading = ref(false);
const error = ref('No activity.');
const chartSeries = ref([]);
const chartLabels = ref([]);

const colors = ['#f59e0b', '#fbbf24'];
const chartOptions = computed(() => ({
  chart: {
    id: 'brand-nonbrand-clicks-chart',
    type: 'donut',

    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },

  colors: colors,

  labels: chartLabels.value,
  legend: {
    show: false,
  },
  tooltip: {
    theme: 'dark',
  },
  noData: {
    text: 'Waiting for data...',
  },
  dataLabels: {
    enabled: false,
  },
}));
const formattedStartDate = computed(() => {
  const options = { year: 'numeric', month: 'short' };
  return new Date(dateStore?.insightDate?.currentStart).toLocaleDateString(
    'en-US',
    options,
  );
});

const formattedEndDate = computed(() => {
  const options = { year: 'numeric', month: 'short', year: 'numeric' };
  return new Date(dateStore?.insightDate?.currentEnd).toLocaleDateString(
    'en-US',
    options,
  );
});
const fetchBrandData = async () => {
  try {
    loading.value = true;
    const response = await Api.get(
      `/google-search-console-insights/brand-clicks`,
      {
        params: {
          start_date: dateStore.insightDate.currentStart,
          end_date: dateStore.insightDate.currentEnd,
        },
      },
    );

    if (response.data?.detail) {
      error.value = response.data.detail;
    } else {
      brandData.value = JSON.parse(response.data?.items.data);
    }
  } catch (error) {
    brandData.value = null;
  } finally {
    loading.value = false;
  }
};

const fetchNonBrandData = async () => {
  try {
    loading.value = true;
    const response = await Api.get(
      `/google-search-console-insights/non-brand-clicks`,
      {
        params: {
          start_date: dateStore.insightDate.currentStart,
          end_date: dateStore.insightDate.currentEnd,
        },
      },
    );
    if (response.data?.detail) {
      error.value = response.data.detail;
    } else {
      nonBrandData.value = JSON.parse(response.data?.items.data);
    }
  } catch (error) {
    nonBrandData.value = null;
  } finally {
    loading.value = false;
  }
};
const fetchData = async () => {
  await fetchBrandData();
  await fetchNonBrandData();
};

watch(
  [brandData, nonBrandData],
  ([newBrandData, newNonBrandData]) => {
    if (newBrandData !== undefined && newNonBrandData !== undefined) {
      const labels = ['Brand Clicks', 'Non-Brand Clicks'];
      const series = [newBrandData, newNonBrandData];

      labelData.value = [
        `Brand - ${formatNumber(newBrandData)}`,
        `Non-Brand - ${formatNumber(newNonBrandData)}`,
      ];

      chartLabels.value = labels;
      chartSeries.value = series;
    }
  },
  { immediate: true },
);

watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam) {
      currentTeam.value = newTeam;
      fetchData();
    }
  },
  {
    immediate: true,
  },
);
watch(
  () => dateStore.insightDate,
  async () => {
    await fetchData();
  },
  { immediate: true, deep: true },
);
</script>
