export function formatNumber(num) {
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
  } else if (num < 1_000 && num > 0) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
  } else {
    return num?.toString();
  }
}
export function calculateDate(days) {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date.toISOString().split('T')[0];
}
export function calculateDateFrom(baseDate, offsetDays) {
  const date = new Date(baseDate);
  date.setDate(date.getDate() + offsetDays);
  return date.toISOString().split('T')[0];
}
export function getDateDifference(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return Math.floor((end - start) / (1000 * 60 * 60 * 24));
}
export function goBack() {
  history.back();
}

export function checkConnectorsMissing(currentTeam) {
  if (!currentTeam) {
    return true;
  }

  const connectorsEmpty = currentTeam.connections.length === 0;

  if (connectorsEmpty) {
    return true;
  }
  const anyConnectorHasConnection = Object.values(currentTeam.connections).some(
    (isConnected) => isConnected === true,
  );
  return !anyConnectorHasConnection;
}
export function toTitleCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

export const clearSession = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('current_team');
  localStorage.removeItem('insight_date');
  localStorage.removeItem('dashboard_date');
};

export const formatChartLabel = (date, groupBy) => {
  const timestamp = new Date(date);
  if (groupBy === 'month') {
    return timestamp.toLocaleDateString('en-GB', {
      month: 'short',
      year: 'numeric',
    });
  }
  if (groupBy === 'week') {
    const weekStart = new Date(timestamp);
    const weekEnd = new Date(timestamp);
    weekStart.setDate(weekStart.getDate() - weekStart.getDay());
    weekEnd.setDate(weekStart.getDate() + 6);
    return `${weekStart.getDate()}-${weekEnd.getDate()} ${weekEnd.toLocaleDateString(
      'en-GB',
      {
        month: 'short',
        year: '2-digit',
      },
    )}`;
  }
  return timestamp.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: '2-digit',
  });
};

export const groupChartData = (data, groupBy, valueKeys = []) => {
  const groupedData = {};
  data.forEach((item) => {
    const timestamp = new Date(item[0]);
    let groupKey;
    if (groupBy === 'month') {
      groupKey = `${timestamp.getFullYear()}-${timestamp.getMonth() + 1}`;
    } else if (groupBy === 'week') {
      const weekStart = new Date(timestamp);
      weekStart.setDate(weekStart.getDate() - weekStart.getDay());
      groupKey = weekStart.toISOString().split('T')[0];
    } else {
      groupKey = timestamp.toISOString().split('T')[0];
    }
    if (!groupedData[groupKey]) {
      groupedData[groupKey] = {
        rawDate: timestamp,
        values: Array(valueKeys.length).fill(0),
      };
    }
    valueKeys.forEach((key, index) => {
      groupedData[groupKey].values[index] += item[key] || 0;
    });
  });
  return Object.entries(groupedData)
    .map(([key, { rawDate, values }]) => ({
      date: key,
      values,
      rawDate,
    }))
    .sort((a, b) => new Date(a.rawDate) - new Date(b.rawDate));
};

export const processId = (id) => {
  return id.replaceAll('-', '');
};

export const validateID = (id) => {
  return id.length >= 4 && !isNaN(id);
};
