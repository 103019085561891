<template>
  <div class="grid grid-rows-10 dark:text-white">
    <div class="row-span-1">
      <div
        class="flex text-lg gap-2 bg-gray-50 dark:bg-black items-center font-semibold h-full w-full py-2 px-4 md:px-6 md:py-4 border-y border-gray-300 dark:border-gray-600"
      >
        <button class="rotate-90" @click.prevent="goBackToTeam">
          <ChevronsDown />
        </button>

        <div
          class="flex items-center justify-center size-12 bg-white rounded-full shadow-lg border border-gray-300 dark:border-gray-600"
        >
          <img
            src="/images/youtube.png"
            alt="YouTube"
            class="size-8 object-contain mix-blend-darken"
          />
        </div>
        <div>YouTube Analytics</div>
      </div>
    </div>
    <div class="row-span-9 grid grid-cols-1 md:grid-cols-2 gap-4">
      <div
        class="w-full h-full flex flex-col justify-center items-center space-y-6"
      >
        <div
          v-if="loading"
          class="bg flex flex-col justify-center items-center h-full w-full"
        >
          <div
            class="animate-bounce flex items-center justify-center size-10 bg-white rounded-full shadow-sm border border-gray-300 dark:border-gray-600"
          >
            <img
              src="/images/youtube.png"
              alt="YouTube"
              class="size-6 object-contain mix-blend-darken"
            />
          </div>

          <div class="text-lg font-semibold font-mono pt-4">Connecting ...</div>
        </div>
        <div v-else>
          <div
            v-if="connected"
            class="font-medium text-gray-800 dark:text-gray-100 text-center lg:text-lg text-base h-full w-full p-4"
          >
            Connected Successfully . Navigating you back .....
          </div>
          <div
            v-else
            class="font-medium text-gray-800 dark:text-gray-100 text-center lg:text-lg text-base h-full w-full p-4"
          >
            <div class="px-4 py-2 text-base md:text-lg">
              All Steps Completed
              <p class="text-sm md:text-base">
                Complete this last step to successfully connect your account
              </p>
            </div>
            <div class="grid grid-cols-2 gap-2 px-4">
              <button
                @click="goBackToTeam"
                class="inline-flex items-center justify-center px-4 py-2 bg-gray-200 border border-transparent rounded-md font-semibold text-xs text-black uppercase tracking-widest hover:bg-gray-300 focus:bg-gray-300 active:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 transition ease-in-out duration-150"
              >
                Cancel setup
              </button>
              <button
                @click="establishConnection"
                class="inline-flex items-center justify-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 focus:bg-gray-700 active:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 transition ease-in-out duration-150"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="p-4 border-x border-gray-300 dark:border-gray-600 text-sm text-gray-600 dark:text-gray-300 text-left space-y-6"
      >
        <p class="font-semibold text-lg text-gray-800 dark:text-gray-100 mb-4">
          To get the YouTube connector up and running, please follow these
          steps:
        </p>

        <div
          class="p-6 border-l-4 border-green-500 bg-gray-50 dark:bg-gray-700 rounded-md"
        >
          <div class="flex items-center justify-between space-x-4">
            <p class="font-semibold text-base text-gray-800 dark:text-gray-100">
              1. Grant Access for YouTube Report
            </p>
            <span class="text-2xl text-green-600">&#10003;</span>
          </div>
          <p class="text-sm text-gray-600 dark:text-gray-300 mt-2">
            First, you'll need to grant access for creating a YouTube report.
          </p>
        </div>

        <div
          class="p-6 border-l-4 border-yellow-500 bg-gray-50 dark:bg-gray-700 rounded-md"
        >
          <div class="flex items-center justify-between space-x-4">
            <p class="font-semibold text-base text-gray-800 dark:text-gray-100">
              2. Grant Access for ETL System Connection
            </p>
            <span v-if="secretId" class="text-2xl text-green-600"
              >&#10003;</span
            >
          </div>
          <p class="text-sm text-gray-600 dark:text-gray-300 mt-2">
            Then, grant access to connect your account with our ETL system.
          </p>
        </div>

        <div
          class="p-6 border-l-4 border-blue-500 bg-gray-50 dark:bg-gray-700 rounded-md"
        >
          <p class="font-semibold text-base text-gray-800 dark:text-gray-100">
            3. Finalize and Redirect
          </p>
          <p class="text-sm text-gray-600 dark:text-gray-300 mt-2">
            Once completed, you'll be redirected to the connections page
            automatically.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Api from '@/utils/ApiClient';
import { ChevronsDown } from 'lucide-vue-next';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';

const secretId = ref();
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const connected = ref(false);

const goBackToTeam = () => {
  router.push('/settings/team/connections');
};
const establishConnection = async () => {
  try {
    loading.value = true;

    const response = await Api.post(
      `/airbyte/youtube-analytics`,
      {
        secret_id: secretId.value,
      },
      {
        params: {
          source_type: 'youtube-analytics',
        },
      },
    );
    if (response.data.message) {
      toast.success(response.data.message || 'Account Connected successfully', {
        onClose: () => {
          goBackToTeam();
        },
      });
      connected.value = true;
    }
  } catch (error) {
    toast.error(error.response.data.detail || 'Account Connection Failed!');
  } finally {
    loading.value = false;
  }
};

onMounted(() => {
  if (route.query.secret_id) {
    secretId.value = route.query.secret_id;
  }
});
</script>
