<template>
  <div
    class="flex flex-col justify-center items-center gap-1 text-base md:text-lg bg-gray-100 dark:bg-black min-h-screen"
    v-if="shouldShowButton"
  >
    <p>Connect your accounts first to see the Insights</p>
    <RouterLink
      to="/settings/team/connections"
      class="px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-gray-700 focus:bg-gray-700 active:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 transition ease-in-out duration-150 max-w-fit dark:bg-amber-500"
    >
      Go to Team Settings
    </RouterLink>
  </div>
  <div
    class="flex-1 dark:bg-black min-h-screen bg-gray-100 mt-14 md:mt-0"
    v-else
  >
    <div
      class="pt-5 flex flex-col gap-3 px-4 sm:flex-row sm:justify-between items-center"
    >
      <div class="text-xl sm:text-2xl font-semibold dark:text-white">
        Insights
      </div>

      <div class="flex flex-col sm:flex-row gap-3 w-full sm:w-auto">
        <div
          @click="toggleData"
          class="flex gap-2 border rounded-md p-2 cursor-pointer text-white bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-xs sm:text-sm justify-center items-center flex-wrap"
        >
          <Sparkles :size="16" class="sm:self-center" /> Explain this to me
        </div>

        <div
          @click="openModal"
          class="flex items-center justify-around p-2 rounded-lg bg-gray-200 dark:bg-gray-800 shadow-md space-x-4 cursor-pointer w-full sm:w-auto"
        >
          <span
            class="text-xs font-semibold text-gray-600 dark:text-gray-300 bg-gray-300 dark:bg-gray-700 px-2 py-1 rounded"
          >
            {{ dayDifference }} days
          </span>

          <div class="flex flex-col items-center sm:items-start">
            <div class="flex items-center">
              <div
                class="text-sm font-semibold dark:text-white text-center sm:text-left"
              >
                {{ formattedStartDate }} - {{ formattedEndDate }}
              </div>

              <div class="ml-2">
                <svg
                  :class="{ 'rotate-180': isModalOpen }"
                  class="transition-transform w-4 h-4 text-gray-600 dark:text-gray-300"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>

            <span
              v-if="isCompareChecked"
              class="text-xs text-gray-600 dark:text-gray-400 text-center sm:text-left"
            >
              Compare: {{ formattedCompareStartDate }} -
              {{ formattedCompareEndDate }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <DatePicker
      :compareRequire="true"
      :isCompareChecked="isCompareChecked"
      :isModalOpen="isModalOpen"
      @toggleCompare="toggleCompare"
      @close="closeModal"
    />
    <div
      v-if="isDataVisible"
      class="p-4 rounded-md bg-white dark:bg-gray-950 m-3"
    >
      <h3
        class="text-base md:text-lg font-bold mb-3 dark:text-gray-100 text-gray-900"
      >
        Insight:
      </h3>
      <div
        class="overflow-auto max-h-80 p-3 bg-gradient-to-r from-gray-100 to-gray-200 dark:bg-gradient-to-r dark:from-gray-800 dark:to-gray-900 rounded"
      >
        <div v-if="isAnimating" class="ai-loader">
          <div
            class="animate-bounce text-transparent font-semibold bg-clip-text bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 text-5xl"
          >
            ✨
          </div>
        </div>
        <pre
          v-else
          class="text-base text-gray-800 dark:text-gray-200 whitespace-pre-wrap font-roboto"
          >{{ apiData }}
          </pre
        >
      </div>
    </div>
    <InsightLayout />
  </div>
</template>
<script setup>
import DatePicker from '@/components/DatePicker.vue';
import {
  checkConnectorsMissing,
  getDateDifference,
} from '@/utils/helperFunctions';
import { computed, ref, watch } from 'vue';
import { useDateStore } from '@/stores/dateStore';
import InsightLayout from '@/layouts/InsightLayout.vue';
import { useTeamStore } from '@/stores/teamStore';
import { RouterLink } from 'vue-router';
import { Sparkles } from 'lucide-vue-next';
import { toast } from 'vue3-toastify';
const dateStore = useDateStore();
const teamStore = useTeamStore();

const startDate = ref(dateStore.insightDate.currentStart);
const endDate = ref(dateStore.insightDate.currentEnd);
const compareStartDate = ref(dateStore.insightDate.pastStart);
const currentTeam = ref(teamStore.current_team);
const compareEndDate = ref(dateStore.insightDate.pastEnd);
const isModalOpen = ref(false);
const isDataVisible = ref(false);
const isAnimating = ref(false);
const stopFetching = ref(false);
const apiData = ref('');

const isCompareChecked = ref(dateStore.isComparing);

let abortController;

const toggleData = () => {
  if (isDataVisible.value) {
    isDataVisible.value = false;
    stopFetching.value = true;
    if (abortController) {
      abortController.abort();
    }
    apiData.value = '';
  } else {
    isDataVisible.value = true;
    stopFetching.value = false;
    fetchData();
  }
};

const backendUrl = import.meta.env.VITE_BACKEND_URL;
const accessToken = localStorage.getItem('access_token');

const fetchData = async () => {
  const eventUrl = dateStore.isComparing
    ? `${backendUrl}/api/ai/insights-summarization?start_date=${startDate.value}&end_date=${endDate.value}&past_start_date=${compareStartDate.value}&past_end_date=${compareEndDate.value}`
    : `${backendUrl}/api/ai/insights-summarization?start_date=${startDate.value}&end_date=${endDate.value}`;

  abortController = new AbortController();
  const { signal } = abortController;

  try {
    isAnimating.value = true;
    const response = await fetch(eventUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'text/stream',
        team_id: currentTeam.value?.id,
      },
      signal,
    });

    if (response.status != 200) {
      toast.error(error.response?.data?.detail || 'Failed to summarize');
      isAnimating.value = false;
      isDataVisible.value = false;
      apiData.value = '';
      if (abortController) {
        abortController.abort();
      }
      return;
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let result = '';

    const processStream = async () => {
      const { done, value } = await reader.read();
      if (done || stopFetching.value) {
        apiData.value = result;
        isAnimating.value = false;
        return;
      }

      result += decoder.decode(value, { stream: true });
      apiData.value = result;

      if (apiData.value.length > 0 && isAnimating.value) {
        isAnimating.value = false;
      }

      processStream();
    };

    processStream();
  } catch (error) {
    toast.error(error.response?.data?.detail || 'Failed to summarize');
    isAnimating.value = false;
    isDataVisible.value = false;
    apiData.value = '';
  }
};

const shouldShowButton = computed(() => {
  if (currentTeam?.value?.id) {
    return checkConnectorsMissing(currentTeam.value);
  }
  return true;
});

const dayDifference = computed(() => {
  return getDateDifference(startDate.value, endDate.value);
});

const formattedStartDate = computed(() => {
  const options = { day: 'numeric', month: 'short' };
  return new Date(startDate.value).toLocaleDateString('en-US', options);
});

const formattedEndDate = computed(() => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return new Date(endDate.value).toLocaleDateString('en-US', options);
});

const formattedCompareStartDate = computed(() => {
  const options = { day: 'numeric', month: 'short' };
  return new Date(compareStartDate.value).toLocaleDateString('en-US', options);
});

const formattedCompareEndDate = computed(() => {
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return new Date(compareEndDate.value).toLocaleDateString('en-US', options);
});
const openModal = () => {
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const toggleCompare = () => {
  isCompareChecked.value = !isCompareChecked.value;
  dateStore.setIsComparing();
};

watch(
  () => teamStore.current_team,
  (newTeam) => {
    currentTeam.value = newTeam;
  },
  {
    immediate: true,
  },
);

watch(
  dateStore.insightDate,
  () => {
    startDate.value = dateStore.insightDate.currentStart;
    endDate.value = dateStore.insightDate.currentEnd;
    compareStartDate.value = dateStore.insightDate.pastStart;
    compareEndDate.value = dateStore.insightDate.pastEnd;
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
