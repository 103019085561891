import Api from '@/utils/ApiClient';
import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    userData: {},
    settingSidebarOpen: false,
  }),
  actions: {
    setUser(userData) {
      this.userData = userData;
    },
    toggleSettingsSidebar() {
      this.settingSidebarOpen = !this.settingSidebarOpen;
    },
    clearUser() {
      this.userData = null;
      this.settingSidebarOpen = false;
    },
    async fetchUserProfile() {
      try {
        const response = await Api.get('/settings');
        if (response?.data?.id) {
          const userData = response.data;
          if (userData?.id) {
            this.setUser(userData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
});
