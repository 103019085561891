<script setup>
import { ref, watch, reactive } from 'vue';
import FormSection from '@/components/FormSection.vue';
import InputError from '@/components/InputError.vue';
import InputLabel from '@/components/InputLabel.vue';
import PrimaryButton from '@/components/PrimaryButton.vue';
import TextInput from '@/components/TextInput.vue';
import { toast } from 'vue3-toastify';
import Api from '@/utils/ApiClient';
import { useUserStore } from '@/stores/userStore';

const form = reactive({
  first_name: '',
  last_name: '',
  email: '',
  errors: {},
  processing: false,
});
const userStore = useUserStore();
const isEditingName = ref(false);
const isNameChanged = ref(false);

watch(
  () => userStore.userData,
  (newUser) => {
    form.first_name = newUser.first_name;
    form.last_name = newUser.last_name;
    form.email = newUser.email || '';
    isNameChanged.value = false;
  },
  { immediate: true, deep: true },
);

watch(
  () => form.first_name,
  (newFirstName) => {
    isNameChanged.value = newFirstName !== userStore.userData.first_name;
  },
);

watch(
  () => form.last_name,
  (newLastName) => {
    isNameChanged.value = newLastName !== userStore.userData.last_name;
  },
);

const updateProfileInformation = async () => {
  form.processing = true;
  form.errors = {};

  if (!form.first_name || !form.last_name) {
    toast.error('Name cannot be empty!');
    return;
  }
  const payload = {
    first_name: form.first_name,
    last_name: form.last_name,
  };

  try {
    const response = await Api.patch('/settings', payload);

    if (response.data.message) {
      userStore.fetchUserProfile();
      toast.success(response.data.message || 'Name updated successfully!');
      isEditingName.value = false;
    }
  } catch (error) {
    toast.error(error.response.data.detail || 'Name updation failed!');
    form.errors = error.response?.data?.errors || {};
  } finally {
    form.processing = false;
  }
};

const startEditingName = () => {
  isEditingName.value = true;
};

const cancelEditingName = () => {
  form.first_name = userStore.userData.first_name;
  form.last_name = userStore.userData.last_name;
  isEditingName.value = false;
};
</script>

<template>
  <FormSection @submitted="updateProfileInformation">
    <template #title> Profile Information </template>

    <template #description>
      Update your account's profile information and email address.
    </template>

    <template #form>
      <div class="col-span-6 sm:col-span-4">
        <div class="grid md:grid-cols-3 gap-2">
          <div>
            <InputLabel for="first_name" value="First Name" />
            <TextInput
              id="first_name"
              v-model="form.first_name"
              type="text"
              class="mt-1 block w-full"
              required
              autocomplete="name"
              :disabled="form.processing || !isEditingName"
            />
          </div>
          <div>
            <InputLabel for="last_name" value="Last Name" />
            <TextInput
              id="last_name"
              v-model="form.last_name"
              type="text"
              class="mt-1 block w-full"
              required
              autocomplete="name"
              :disabled="form.processing || !isEditingName"
            />
          </div>
          <div class="flex items-end justify-center">
            <button
              v-if="!isEditingName"
              @click="startEditingName"
              :disabled="form.processing"
              class="inline-flex items-center px-3 py-1 bg-white border border-gray-300 rounded-md font-semibold text-xs text-gray-700 uppercase tracking-widest shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25 transition ease-in-out duration-150"
            >
              Edit
            </button>

            <button
              @click="cancelEditingName"
              v-else
              class="inline-flex items-center px-3 py-1 bg-white border border-gray-300 rounded-md font-semibold text-xs text-gray-700 uppercase tracking-widest shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25 transition ease-in-out duration-150"
            >
              Cancel
            </button>
          </div>
        </div>

        <InputError :message="form.errors.name" class="mt-2" />
      </div>

      <div class="col-span-6 sm:col-span-4">
        <InputLabel for="useremail" value="Email" />
        <TextInput
          id="useremail"
          :value="form.email"
          type="email"
          class="mt-1 block w-full"
          required
          autocomplete="username"
          disabled
        />
        <InputError :message="form.errors.email" class="mt-2" />

        <div class="col-span-6 sm:col-span-4 p-1">
          <p class="text-sm mt-2">Your email address is verified.</p>
        </div>
      </div>
    </template>

    <template #actions>
      <PrimaryButton
        :class="{ 'opacity-25': form.processing || !isNameChanged }"
        :disabled="form.processing || !isNameChanged"
      >
        Save
      </PrimaryButton>
    </template>
  </FormSection>
</template>
