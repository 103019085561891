<template>
  <PaginatedTable
    title="Top Campaigns"
    subtitle="Based on Lowest Cost Per Conversion "
    column1Title="Campaign Name"
    column2Title="Cost Per Conversion"
    apiUrl="/google-ads-insights/campaign-with-lowest-cost-per-conversion"
    :displayIndex="index"
    :formatter="formatData"
    srcName="gads"
  />
</template>

<script setup>
import PaginatedTable from '@/components/PaginatedTable.vue';
const index = [0, 1];
const formatData = (data) => '₹' + data;
</script>
