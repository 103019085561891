<template>
  <MetricCard
    apiEndpoint="/youtube-analytics/watch-time"
    metricName="Watch Time"
    :formatData="formatWatchTime"
    srcName="YouTube Analytics"
  />
</template>

<script setup>
import MetricCard from '@/components/MetricCard.vue';
const formatWatchTime = (data) => data?.data + ' mins';
</script>
