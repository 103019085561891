<template>
  <MetricCard
    apiEndpoint="/amazon-ads/conversions"
    metricName="Conversions"
    :formatData="formatData"
    srcName="Amazon Ads"
  />
</template>

<script setup>
import { formatNumber } from '@/utils/helperFunctions';
import MetricCard from '@/components/MetricCard.vue';

const formatData = (data) => formatNumber(data.data);
</script>
