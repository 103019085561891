<template>
  <div
    class="flex flex-col rounded-lg overflow-hidden py-2 px-2 md:px-4 bg-white dark:bg-gray-950 dark:text-white border dark:border-gray-700 min-h-full flex-grow"
  >
    <div class="">{{ currentMonth }}</div>

    <div class="flex flex-col items-center justify-around text-base md:text-lg">
      <div class="text-lg md:text-xl font-medium">
        {{ growth }}
      </div>
      Followers Gained
    </div>
    <div v-if="loading" class="flex flex-1 justify-center">
      <StyledLoader />
    </div>
    <div v-else class="flex-1 grid">
      <div
        class="my-2 mx-auto flex-grow flex justify-center items-center"
        v-if="data.length"
      >
        <apexchart
          type="line"
          :options="chartOptions"
          :series="chartSeries"
          class="flex-1"
        ></apexchart>
      </div>
      <div v-else class="flex justify-center items-center text-base md:text-lg">
        No activity.
      </div>
    </div>
    <SourceBadge src="/images/tiktok.png" alt="TikTok" label="TikTok" />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import Api from '@/utils/ApiClient';
import { useTeamStore } from '@/stores/teamStore';
import StyledLoader from '@/components/StyledLoader.vue';
import SourceBadge from '@/components/SourceBadge.vue';
import { formatNumber } from '@/utils/helperFunctions';

const data = ref([]);
const labelData = ref();
const chartLabels = ref([]);
const teamStore = useTeamStore();
const currentMonth = ref();
const growth = ref('');
const currentTeam = ref(teamStore.current_team);
const loading = ref(false);
const categories = ref([]);
const chartOptions = computed(() => ({
  chart: {
    id: 'followers-gained-chart',
    type: 'line',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  colors: ['#bc2a8d'],
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  xaxis: {
    categories: categories.value,
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  grid: {
    show: false,
  },
  tooltip: {
    x: {
      formatter: function (value, { seriesIndex, dataPointIndex }) {
        const category = categories.value[dataPointIndex];
        return `${category}`;
      },
    },
    theme: 'dark',
  },
  noData: {
    text: 'No Activity',
  },
  legend: {
    position: 'bottom',
    labels: {
      useSeriesColors: true,
    },
  },
}));

const chartSeries = ref([
  {
    name: 'Followers Gained',
    data: [],
  },
]);

const fetchData = async () => {
  try {
    loading.value = true;
    const response = await Api.get(`/tiktok/followers-growth`);
    if (response.data) {
      data.value = JSON.parse(response.data.items.data);
      if (Array.isArray(data.value) && data.value.length > 0) {
        categories.value = data.value.map((item) => {
          const temp = new Date(item[0]);
          return temp.toLocaleDateString('en-GB', {
            month: 'short',
            year: 'numeric',
          });
        });
        currentMonth.value = categories.value[categories.value.length - 1];
        const followers_gained = data.value.map((item) => item[1]);
        if (followers_gained.length > 0) {
          growth.value = formatNumber(
            followers_gained[followers_gained.length - 1],
          );
        }
        chartSeries.value = [
          {
            name: 'Followers Gained',
            data: followers_gained,
          },
        ];
      }
    }
  } catch (error) {
    data.value = [];
    labelData.value = [];
    chartLabels.value = [];
    chartSeries.value = [];
  } finally {
    loading.value = false;
  }
};

watch(
  () => teamStore.current_team,
  (newTeam) => {
    if (newTeam) {
      currentTeam.value = newTeam;
      fetchData();
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
