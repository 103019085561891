import { defineStore } from 'pinia';
import Api from '@/utils/ApiClient';

export const useTeamStore = defineStore('team', {
  state: () => ({
    current_team: null,
    teams: [],
    members: [],
    fetchingMembers: false,
    refetchNewTeamsFlag: false,
  }),
  actions: {
    setTeams(teams) {
      this.teams = [...teams];
      try {
        const storedTeam = localStorage.getItem('current_team');
        if (storedTeam) {
          const currentTeamFromStorage = JSON.parse(storedTeam);
          const updatedTeam = this.teams.find(
            (team) => team.id === currentTeamFromStorage.id,
          );

          if (
            updatedTeam &&
            updatedTeam.connections !== currentTeamFromStorage.connections
          ) {
            this.setCurrentTeam(updatedTeam);
          } else if (!updatedTeam) {
            localStorage.removeItem('current_team');
          }
        } else {
          this.setCurrentTeam(this.teams[0]);
        }
      } catch (err) {
        this.setCurrentTeam(this.teams[0]);
      }
    },
    setCurrentTeam(team) {
      this.current_team = team;
      if (team) {
        localStorage.setItem('current_team', JSON.stringify(team));
      }
    },
    clearTeam() {
      this.current_team = null;
      this.members = [];
      localStorage.removeItem('current_team');
      this.teams = [];
      this.refetchNewTeamsFlag = false;
    },
    async fetchMembers() {
      try {
        this.fetchingMembers = true;
        const response = await Api.get('/team/members');
        if (response.data) {
          this.members = response.data;
        }
      } catch (error) {
        this.members = [];
      } finally {
        this.fetchingMembers = false;
      }
    },
    setMembers(members) {
      this.members = [...members];
    },
  },
});
